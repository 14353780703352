import React from "react";
import Lottie from "react-lottie";
import arrow from "../../assets/lotties/arrow.json";

const ArrowComponent = () => {
  const defaultOption = {
    loop: true,
    autoplay: true,
    animationData: arrow,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      style={{
        fontSize: 11,
        color: "#fff",
        position: "absolute",
        zIndex: 1000,
        right: "10px",
        top: "40px",
        backgroundColor: "rgba(0,0,0,0.6)",
        borderRadius: "5px",
        padding: "0px 5px 10px",
      }}
    >
      <Lottie options={defaultOption} height={40} width={120} />
      <span>밀어서 갤러리 사진 보기</span>
    </div>
  );
};

export default ArrowComponent;
