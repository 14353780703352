import React, { useState, useEffect } from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { dbService } from "../../../fbase";
import "./White.css";

import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import {
  faMapMarkerAlt,
  faMobileAlt,
  faShare,
  faSms,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment, { Moment as MomentTypes } from "moment";
import CustomMap from "../../../components/CustomMap/CustomMap";

import kakaoImg from "../../../assets/images/kakaopay.png";
import flowerImg_1 from "../../../assets/images/flower.png";
import flowerImg_2 from "../../../assets/images/flower2.png";
import flowerImg_3 from "../../../assets/images/flower3.png";

import { useParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

import Loading from "../../Loading/Loading";

const params = {
  effect: "fade",
  pagination: {
    el: ".swiper-pagination",
    clickable: false,
    dynamicBullets: true,
  },
};
const transMon = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

const CustomChewyul = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isImgLoading, setIsImgLoading] = useState(false);
  const [isSharu, setIsSharu] = useState(false);

  const [husbandName, setHusbandName] = useState("");
  const [husbandShortName, setHusbandShortName] = useState("");
  const [husbandPhone, setHusbandPhone] = useState("");
  const [husbandKakaoPay, setHusbandKakaoPay] = useState("");
  const [husbandPapa, setHusbandPapa] = useState("");
  const [husbandMama, setHusbandMama] = useState("");
  const [husbandFirstSon, setHusbandFirstSon] = useState("");
  const [husbandAccount, setHusbandAccount] = useState("");
  const [husbandBank, setHusbandBank] = useState("");
  const [husbandAccountName, setHusbandAccountName] = useState("");
  const [husbandHostPhone, setHusbandHostPhone] = useState("");

  const [wifeName, setWifeName] = useState("");
  const [wifeShortName, setWifeShortName] = useState("");
  const [wifePhone, setWifePhone] = useState("");
  const [wifeKakaoPay, setWifeKakaoPay] = useState("");
  const [wifePapa, setWifePapa] = useState("");
  const [wifeMama, setWifeMama] = useState("");
  const [wifeFirstDau, setWifeFirstDau] = useState("");
  const [wifeAccount, setWifeAccount] = useState("");
  const [wifeBank, setWifeBank] = useState("");
  const [wifeAccountName, setWifeAccountName] = useState("");
  const [wifeHostPhone, setWifeHostPhone] = useState("");

  const [weddingDate, setWeddingDate] = useState("");
  const [weddingLocation_1, setWeddingLocation_1] = useState("");
  const [weddingLocation_2, setWeddingLocation_2] = useState("");
  const [weddingNumber, setWeddingNumber] = useState("");
  const [weddingHow, setWeddingHow] = useState("");
  const [weddingAddress, setWeddingAddress] = useState("");
  const [invatationContent, setInvatationContent] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [kakaoTitle, setKakaoTitle] = useState("");

  const [basic_1, setBasic_1] = useState("");
  const [basic_2, setBasic_2] = useState("");
  const [basic_3, setBasic_3] = useState("");
  const [basic_4, setBasic_4] = useState("");
  const [basic_5, setBasic_5] = useState("");
  const [basic_6, setBasic_6] = useState("");
  const [basic_7, setBasic_7] = useState("");

  const [basic_8, setBasic_8] = useState("");
  const [basic_9, setBasic_9] = useState("");

  const [optionLeftName, setOptionLeftName] = useState("");
  const [optionLeftBank, setOptionLeftBank] = useState("");
  const [optionLeftAccount, setOptionLeftAccount] = useState("");

  const [optionRightName, setOptionRightName] = useState("");
  const [optionRightBank, setOptionRightBank] = useState("");
  const [optionRightAccount, setOptionRightAccount] = useState("");

  const [mainImg, setMainImg] = useState("");
  const [galleryImg_1, setGalleryImg_1] = useState("");
  const [galleryImg_2, setGalleryImg_2] = useState("");
  const [galleryImg_3, setGalleryImg_3] = useState("");
  const [galleryImg_4, setGalleryImg_4] = useState("");
  const [galleryImg_5, setGalleryImg_5] = useState("");
  const [galleryImg_6, setGalleryImg_6] = useState("");
  const [galleryImg_7, setGalleryImg_7] = useState("");
  const [galleryImg_8, setGalleryImg_8] = useState("");
  const [galleryImg_9, setGalleryImg_9] = useState("");
  const [galleryImg_10, setGalleryImg_10] = useState("");

  const [galleryArr, setGalleryArr] = useState([]);

  const { id, path } = useParams();

  const getUser = async () => {
    const dbUser = await dbService.collection("users").doc(id).get();

    const data = await dbUser.data();
    console.log(data);
    setIsSharu(data.isSharu);
    setHusbandName(data.husbandName);
    setHusbandShortName(data.husbandShortName);
    setHusbandPhone(data.husbandPhone);
    setHusbandKakaoPay(data.husbandKakaoPay);
    setHusbandPapa(data.husbandPapa);
    setHusbandMama(data.husbandMama);
    setHusbandFirstSon(data.husbandFirstSon);
    setHusbandAccount(data.husbandAccount);
    setHusbandBank(data.husbandBank);
    setHusbandAccount(data.husbandAccount);
    setHusbandAccountName(data.husbandAccountName);
    setHusbandHostPhone(data.husbandHostPhone);

    setWifeName(data.wifeName);
    setWifeShortName(data.wifeShortName);
    setWifePhone(data.wifePhone);
    setWifeKakaoPay(data.wifeKakaoPay);
    setWifePapa(data.wifePapa);
    setWifeMama(data.wifeMama);
    setWifeFirstDau(data.wifeFirstDau);
    setWifeAccount(data.wifeAccount);
    setWifeBank(data.wifeBank);
    setWifeAccountName(data.wifeAccountName);
    setWifeHostPhone(data.wifeHostPhone);

    setWeddingDate(data.weddingDate);
    setWeddingLocation_1(data.weddingLocation_1);
    setWeddingLocation_2(data.weddingLocation_2);
    setWeddingNumber(data.weddingNumber);
    setWeddingHow(data.weddingHow);
    setWeddingAddress(data.weddingAddress);
    setInvatationContent(data.invatationContent);
    setLongitude(data.longitude);
    setLatitude(data.latitude);
    setKakaoTitle(data.kakaoTitle);

    setBasic_1(data.basic_1);
    setBasic_2(data.basic_2);
    setBasic_3(data.basic_3);
    setBasic_4(data.basic_4);
    setBasic_5(data.basic_5);
    setBasic_6(data.basic_6);
    setBasic_7(data.basic_7);
    if (data.basic_8) {
      setBasic_8(data.basic_8);
    }

    if (data.basic_9) {
      setBasic_9(data.basic_9);
    }

    if (data.optionLeftAccount) {
      setOptionLeftAccount(data.optionLeftAccount);
    }

    if (data.optionLeftName) {
      setOptionLeftName(data.optionLeftName);
    }

    if (data.optionLeftBank) {
      setOptionLeftBank(data.optionLeftBank);
    }

    if (data.optionRightAccount) {
      setOptionRightAccount(data.optionRightAccount);
    }

    if (data.optionRightName) {
      setOptionRightName(data.optionRightName);
    }

    if (data.optionRightBank) {
      setOptionRightBank(data.optionRightBank);
    }
    setMainImg(data.downloadUrlMain);
    setGalleryArr(
      [
        data.galleryUrl_1 !== "" && data.galleryUrl_1,
        data.galleryUrl_2 !== "" && data.galleryUrl_2,
        data.galleryUrl_3 !== "" && data.galleryUrl_3,
        data.galleryUrl_4 !== "" && data.galleryUrl_4,
        data.galleryUrl_5 !== "" && data.galleryUrl_5,
        data.galleryUrl_6 !== "" && data.galleryUrl_6,
        data.galleryUrl_7 !== "" && data.galleryUrl_7,
        data.galleryUrl_8 !== "" && data.galleryUrl_8,
        data.galleryUrl_9 !== "" && data.galleryUrl_9,
        data.galleryUrl_10 !== "" && data.galleryUrl_10,
      ].filter(arrayFilter)
    );
    console.log("222");

    await setIsLoading(true);

    if (data.weddingAddress !== "") {
      await window.Kakao.Link.createDefaultButton({
        container: "#kakao-link-btn",
        objectType: "location",
        address: data.weddingAddress,
        content: {
          title:
            "김채율♥김명회 결혼식에 초대합니다. \n- 2021년 1월 9일 (토) 오후 1시\n- 호텔인터불고대구(만촌)\n- 웨딩인터빌리지 홀",
          description: "",
          imageUrl: data.downloadUrlMain,
          imageHeight: 800,
          link: {
            mobileWebUrl: window.location.href,
            androidExecParams: "invite",
          },
        },
        buttons: [
          {
            title: "모바일청첩장",
            link: {
              mobileWebUrl: window.location.href,
            },
          },
        ],
      });
    } else {
      await window.Kakao.Link.createDefaultButton({
        container: "#kakao-link-btn",
        objectType: "feed",
        content: {
          title: data.kakaoTitle,
          description: "",
          imageUrl: data.downloadUrlMain,
          imageHeight: 800,
          link: {
            mobileWebUrl: window.location.href,
            androidExecParams: "invite",
          },
        },
        buttons: [
          {
            title: "모바일청첩장",
            link: {
              mobileWebUrl: window.location.href,
            },
          },
        ],
      });
    }
  };

  const generate = () => {
    const today = moment(weddingDate.slice(0, 10));
    const startWeek = today.clone().startOf("month").week();
    const endWeek =
      today.clone().endOf("month").week() === 1
        ? 53
        : today.clone().endOf("month").week();
    let calendar = [];
    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <div className="calendar-height-premium" key={week}>
          {Array(7)
            .fill(0)
            .map((n, i) => {
              let current = today
                .clone()
                .week(week)
                .startOf("week")
                .add(n + i, "day");
              let isSelected =
                today.format("YYYYMMDD") === current.format("YYYYMMDD")
                  ? "selected-premium"
                  : "";
              let isGrayed =
                current.format("MM") === today.format("MM")
                  ? ""
                  : "grayed-premium";
              return (
                <div
                  className={`calendar-division-premium calendar-num-premium `}
                  key={i}
                >
                  <span className={`${isSelected} ${isGrayed}`}>
                    {current.format("D")}
                  </span>
                </div>
              );
            })}
        </div>
      );
    }
    return calendar;
  };

  const translateDate = (abc) => {
    var weekday = new Array();
    weekday[0] = "일요일";
    weekday[1] = "월요일";
    weekday[2] = "화요일";
    weekday[3] = "수요일";
    weekday[4] = "목요일";
    weekday[5] = "금요일";
    weekday[6] = "토요일";

    var date = new Date(abc.slice(0, 10) + "T00:00:00Z");
    var year = date.getFullYear();
    var month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1).toString()
        : date.getMonth() + 1;
    var day =
      date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate();
    var hours =
      abc.slice(11, 13) < 10
        ? "오전 0" + abc.slice(11, 13).toString() + "시"
        : abc.slice(11, 13) > 12
        ? "오후 " + (abc.slice(11, 13) - 12).toString() + "시"
        : abc.slice(11, 13) == 12
        ? "오후 " + abc.slice(11, 13).toString() + "시"
        : "오전 " + abc.slice(11, 13).toString() + "시";
    var min = abc.slice(14, 16) == 0 ? "" : abc.slice(14, 16) + "분";
    var week = date.getDay();

    return `${year}.${month}.${day} ${weekday[week]} ${hours} ${min}`;
  };

  const translateDateEng = (abc) => {
    var weekday = new Array();
    weekday[0] = "SUN";
    weekday[1] = "MON";
    weekday[2] = "TUE";
    weekday[3] = "WED";
    weekday[4] = "THU";
    weekday[5] = "FRI";
    weekday[6] = "SAT";

    var date = new Date(abc.slice(0, 10) + "T00:00:00Z");
    var year = date.getFullYear();
    var month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1).toString()
        : date.getMonth() + 1;
    var day =
      date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate();
    var hours =
      abc.slice(11, 13) < 10
        ? "0" + abc.slice(11, 13)
        : abc.slice(11, 13) > 12
        ? abc.slice(11, 13) - 12
        : abc.slice(11, 13);
    var min =
      abc.slice(14, 16) < 10
        ? abc.slice(11, 13) >= 12
          ? abc.slice(14, 16).toString() + "PM"
          : abc.slice(14, 16).toString() + "AM"
        : abc.slice(11, 13) >= 12
        ? abc.slice(14, 16) + "PM"
        : abc.slice(14, 16) + "AM";
    var week = date.getDay();

    return `${year}.${month}.${day} ${weekday[week]} ${hours}:${min}`;
  };

  function arrayFilter(value) {
    return value !== false;
  }

  useEffect(() => {
    getUser();
    console.log("11111");
    window.Kakao.init("f9e17c5a4de61c4456e235636385c6b9");

    console.log(id);
    console.log(path);
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          className="white"
          style={{
            fontFamily: "NanumSquare",
            position: "relative",
            paddingBottom: 100,
          }}
        >
          {/* 메인 */}
          <div className="text-focus-in" style={{ position: "relative" }}>
            <div
              style={{
                marginLeft: 16,
                marginTop: 16,
                width: window.outerWidth - 32,
                height: "100%",
                position: "absolute",
              }}
            >
              <div
                style={{
                  border: "1px solid",
                  borderColor: "#999",
                  width: "100%",
                  height: "100%",
                }}
              ></div>
            </div>
            <div
              style={{
                height: isImgLoading ? "auto" : "90vh",
              }}
            >
              <img
                src={mainImg}
                onLoad={async () => {
                  await setIsImgLoading(true);
                  await Aos.init({ duration: 2000 });
                }}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            </div>

            <div style={{ paddingBottom: 10 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "30px 0",
                  fontSize: 20,
                  fontFamily: "Nanum Myeongjo",
                }}
              >
                <span style={{ color: "#999" }}>{husbandName}</span>
                <span style={{ padding: "0 30px", color: "#999" }}>|</span>
                <span style={{ color: "#999" }}>{wifeName}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#333",
                  lineHeight: "15px",
                }}
              >
                <span style={{ fontSize: 14 }}>
                  {translateDateEng(weddingDate)}
                </span>

                <span
                  style={{
                    fontSize: 12,
                    paddingTop: 2,
                  }}
                >
                  {weddingLocation_1}
                </span>
              </div>
            </div>
          </div>
          {/* 메인 */}

          {/* 인사말 */}
          <div className="text-focus-in" style={{ padding: "100px 0 90px" }}>
            <div
              data-aos={isSharu ? "fade-up" : ""}
              style={{ textAlign: "center", paddingBottom: 10 }}
            >
              <img src={flowerImg_1} style={{ width: 40 }} />
            </div>
            <div
              data-aos={isSharu ? "fade-up" : ""}
              style={{
                textAlign: "center",
                fontSize: 16,
                color: "#999",
                lineHeight: "30px",
                fontFamily: "Nanum-Garam",
                fontWeight: "700",
              }}
            >
              {ReactHtmlParser(invatationContent)}
            </div>

            {husbandPapa === "" && husbandMama === "" ? (
              false
            ) : (
              <div
                style={{
                  textAlign: "center",
                  paddingTop: 70,
                  lineHeight: "26px",
                  fontSize: 16,
                  fontFamily: "Nanum Myeongjo",
                }}
              >
                <div data-aos={isSharu ? "fade-up" : ""} style={{}}>
                  <span style={{ color: "#999" }}>
                    {husbandPapa}
                    {husbandMama !== "" ? " " : false}
                    {husbandMama}
                    <span style={{ fontSize: 14 }}>의 {husbandFirstSon}</span>
                  </span>{" "}
                  <span style={{ color: "#333" }}>{husbandShortName}</span>
                </div>
                <div data-aos={isSharu ? "fade-up" : ""} style={{}}>
                  <span style={{ color: "#999" }}>
                    {wifePapa}
                    {wifeMama !== "" ? " " : false}
                    {wifeMama}
                    <span style={{ fontSize: 14 }}>의 {wifeFirstDau}</span>
                  </span>{" "}
                  <span style={{ color: "#333" }}>{wifeShortName}</span>
                </div>
              </div>
            )}

            <div
              data-aos={isSharu ? "fade-up" : ""}
              style={{
                textAlign: "center",
                paddingTop: 40,
                color: "#999",
                fontSize: 12,
              }}
            >
              {translateDate(weddingDate)}
              <br />
              {weddingLocation_1}
            </div>
          </div>
          {/* 인사말 */}

          <div style={{}}>
            <div
              data-aos={isSharu ? "fade-up" : ""}
              style={{ paddingBottom: 40 }}
            >
              <Swiper {...params}>
                {galleryArr.map((item, index) => {
                  return (
                    <div
                      style={{ backgroundColor: "#fff", paddingBottom: 20 }}
                      key={index}
                    >
                      <img
                        src={item}
                        style={{
                          width: "100%",
                          height: 400,
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  );
                })}
              </Swiper>
            </div>

            <div data-aos={isSharu ? "fade-up" : ""}>
              {/* <div
                className="MainName text-focus-in"
                style={{ fontSize: 20, marginBottom: 20 }}
              >
                Calendar
              </div> */}

              <div className="calendar">
                <span className="calendar-month">
                  {
                    transMon[
                      new Date(
                        weddingDate.slice(0, 10) + "T00:00:00Z"
                      ).getMonth()
                    ]
                  }
                </span>
                <div>
                  <span className="calendar-division calendar-day">SUN</span>
                  <span className="calendar-division calendar-day">MON</span>
                  <span className="calendar-division calendar-day">TUE</span>
                  <span className="calendar-division calendar-day">WED</span>
                  <span className="calendar-division calendar-day">THU</span>
                  <span className="calendar-division calendar-day">FRI</span>
                  <span className="calendar-division calendar-day">SAT</span>
                </div>
                {generate()}
              </div>
            </div>

            {/* 지도 */}
            <div
              data-aos={isSharu ? "fade-up" : ""}
              style={{ textAlign: "center" }}
            >
              <div style={{ fontFamily: "Nanum Myeongjo" }}>
                {latitude !== "" ? (
                  <div
                    className="text-focus-in"
                    style={{ fontSize: 30, marginBottom: 10, color: "#333" }}
                  >
                    <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                  </div>
                ) : (
                  false
                )}

                <h3 style={{ fontWeight: "bold", fontSize: 18, color: "#333" }}>
                  {weddingLocation_2}
                </h3>

                {weddingAddress === "" && weddingNumber === "" ? (
                  false
                ) : (
                  <div
                    style={{
                      paddingBottom: "3em",
                      fontSize: 12,
                      paddingLeft: 16,
                      paddingRight: 16,
                      color: "#333",
                    }}
                  >
                    {weddingAddress !== "" ? (
                      <span style={{ wordBreak: "keep-all" }}>
                        {ReactHtmlParser(weddingAddress)}
                      </span>
                    ) : (
                      false
                    )}

                    <br />
                    {weddingNumber !== "" ? (
                      <span style={{ display: "inline-block", paddingTop: 6 }}>
                        Tel.{weddingNumber}
                      </span>
                    ) : (
                      false
                    )}
                    <br />
                  </div>
                )}
              </div>
              {latitude !== "" ? (
                <CustomMap lat={latitude} lng={longitude} />
              ) : (
                false
              )}

              <div data-aos={isSharu ? "fade-up" : ""}>
                <div
                  className="location-text"
                  style={{ color: "#333 !important" }}
                >
                  {weddingHow != "undefined"
                    ? ReactHtmlParser(weddingHow)
                    : false}
                  {/* <h4>지하철</h4>
              <span>지하철 2호선 센텀시티역 4번 출구 (도보 13분)</span>

              <h4>시내버스</h4>
              <span>SK텔레콤 정류장 하차 (도보 3분)</span>
              <br />
              <span>39, 40, 63, 115, 139, 141, 155</span>
              <br />
              <span>센텀시티역 하차 (도보 13분)</span>
              <br />
              <span>181, 307, 1001, 1002</span>

              <h4>주차장</h4>
              <span>건물 지하주차장(B1F-B4F) 2시간 무료</span> */}
                </div>
              </div>
            </div>
            {/* 지도 */}

            <div style={{ paddingBottom: 50, paddingTop: 50 }}>
              <div data-aos={isSharu ? "fade-up" : ""}>
                <div style={{ fontSize: 20 }}>
                  <img src={flowerImg_2} style={{ width: 40 }} />
                </div>
                <p
                  style={{
                    fontSize: 14,
                    margin: 0,
                    paddingBottom: 30,
                    color: "#333",
                  }}
                >
                  {ReactHtmlParser(basic_1)}
                </p>
              </div>
              {path === "account" ? (
                <div
                  data-aos={isSharu ? "fade-up" : ""}
                  style={{
                    padding: "10px 16px 40px",
                    fontFamily: '"Baloo 2", cursive',
                  }}
                >
                  <div style={{ border: "1px solid #887f6e", borderRadius: 6 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      {husbandAccountName === "" ? (
                        false
                      ) : (
                        <div
                          style={{
                            padding: "20px 8px",
                            borderRight:
                              wifeAccountName === ""
                                ? "none"
                                : "1px solid #887f6e",
                            flex: 1,
                            height: 80,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              paddingBottom: 10,
                              fontSize: 16,
                              color: "#49301e",
                              fontWeight: "bold",
                            }}
                          >
                            {basic_6}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                            예금주 : {husbandAccountName}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                            {husbandBank} : {husbandAccount}
                          </p>
                        </div>
                      )}

                      {wifeAccountName === "" ? (
                        false
                      ) : (
                        <div
                          style={{
                            padding: "20px 8px",
                            flex: 1,
                            height: 80,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              paddingBottom: 10,
                              fontSize: 16,
                              color: "#49301e",
                              fontWeight: "bold",
                            }}
                          >
                            {basic_7}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                            예금주 : {wifeAccountName}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                            {wifeBank} : {wifeAccount}
                          </p>
                        </div>
                      )}
                    </div>

                    {/* 계좌번호 하단 */}
                    {optionLeftAccount === "" && optionRightAccount === "" ? (
                      false
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          borderTop: "1px solid #887f6e",
                        }}
                      >
                        <div
                          style={{
                            padding: "20px 8px",
                            borderRight: "1px solid #887f6e",
                            flex: 1,
                            height: 80,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              paddingBottom: 10,
                              fontSize: 16,
                              color: "#49301e",
                              fontWeight: "bold",
                            }}
                          >
                            {basic_8}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                            예금주 : {optionLeftName}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                            {optionLeftBank} : {optionLeftAccount}
                          </p>
                        </div>
                        <div
                          style={{
                            padding: "20px 8px",
                            flex: 1,
                            height: 80,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              paddingBottom: 10,
                              fontSize: 16,
                              color: "#49301e",
                              fontWeight: "bold",
                            }}
                          >
                            {basic_9}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                            예금주 : {optionRightName}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                            {optionRightBank} : {optionRightAccount}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* 계좌번호 하단 */}
                    {/* 계좌번호 하단 */}
                    {/* <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        borderTop: "1px solid #887f6e",
                      }}
                    >
                      <div
                        style={{
                          padding: "20px 8px",
                          borderRight: "1px solid #887f6e",
                          width: "50%",
                          height: 80,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            paddingBottom: 10,
                            fontSize: 16,
                            color: "#49301e",
                            fontWeight: "bold",
                          }}
                        >
                          신랑측 혼주
                        </p>
                        <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                          예금주 :{husbandAccountName}
                        </p>
                        <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                          {husbandBank} : {husbandAccount}
                        </p>
                      </div>
                      <div
                        style={{
                          padding: "20px 8px",
                          width: "50%",
                          height: 80,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            paddingBottom: 10,
                            fontSize: 16,
                            color: "#49301e",
                            fontWeight: "bold",
                          }}
                        >
                          신부측 혼주
                        </p>
                        <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                          예금주 :{wifeAccountName}
                        </p>
                        <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                          {wifeBank} : {wifeAccount}
                        </p>
                      </div>
                    </div> */}
                    {/* 계좌번호 하단 */}
                  </div>
                </div>
              ) : (
                false
              )}

              <div
                data-aos={isSharu ? "fade-up" : ""}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  paddingTop: 20,
                  paddingRight: 16,
                  paddingLeft: 16,
                }}
              >
                {husbandPhone === "" ? (
                  false
                ) : (
                  <div style={{ display: "inline-block", flex: 1 }}>
                    <p>{ReactHtmlParser(basic_2)}</p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <a
                        href={`tel:${husbandPhone}`}
                        style={{ display: "inline-block", padding: "0 5px" }}
                      >
                        <div
                          style={{
                            fontSize: 20,
                            display: "inline-block",
                            backgroundColor: "#000",
                            color: "#fff",
                            height: 30,
                            width: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 6,
                          }}
                        >
                          <FontAwesomeIcon icon={faPhone} size="20" />
                        </div>
                      </a>
                      {husbandKakaoPay !== "" ? (
                        <div style={{ display: "inline-block" }}>
                          <a
                            href={`https://qr.kakaopay.com/${husbandKakaoPay}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={kakaoImg}
                              style={{
                                width: "4em",
                                borderRadius: "200px",
                                padding: "0 5px",
                              }}
                            />
                          </a>
                        </div>
                      ) : (
                        <a
                          href={`sms:${husbandPhone}`}
                          style={{ display: "inline-block", padding: "0 5px" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              display: "inline-block",
                              backgroundColor: "#000",
                              color: "#fff",
                              height: 30,
                              width: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 6,
                            }}
                          >
                            <FontAwesomeIcon icon={faSms} size="20" />
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                )}

                {wifePhone === "" ? (
                  false
                ) : (
                  <div style={{ display: "inline-block", flex: 1 }}>
                    <p>{ReactHtmlParser(basic_3)}</p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <a
                        href={`tel:${wifePhone}`}
                        style={{ display: "inline-block", padding: "0 5px" }}
                      >
                        <div
                          style={{
                            fontSize: 20,
                            display: "inline-block",
                            backgroundColor: "#000",
                            color: "#fff",
                            height: 30,
                            width: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 6,
                          }}
                        >
                          <FontAwesomeIcon icon={faPhone} size="20" />
                        </div>
                      </a>
                      {wifeKakaoPay !== "" ? (
                        <div style={{ display: "inline-block" }}>
                          <a
                            href={`https://qr.kakaopay.com/${wifeKakaoPay}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={kakaoImg}
                              style={{
                                width: "4em",
                                borderRadius: "200px",
                                padding: "0 5px",
                              }}
                            />
                          </a>
                        </div>
                      ) : (
                        <a
                          href={`sms:${wifePhone}`}
                          style={{ display: "inline-block", padding: "0 5px" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              display: "inline-block",
                              backgroundColor: "#000",
                              color: "#fff",
                              height: 30,
                              width: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 6,
                            }}
                          >
                            <FontAwesomeIcon icon={faSms} size="20" />
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* 혼주 연락처 */}

              {husbandHostPhone === "" && wifeHostPhone === "" ? (
                false
              ) : (
                <div
                  data-aos={isSharu ? "fade-up" : ""}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    paddingTop: 40,
                    paddingRight: 16,
                    paddingLeft: 16,
                  }}
                >
                  {husbandHostPhone !== "" ? (
                    <div style={{ display: "inline-block", flex: 1 }}>
                      <p>{ReactHtmlParser(basic_4)}</p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href={`tel:${husbandHostPhone}`}
                          style={{ display: "inline-block", padding: "0 5px" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              display: "inline-block",
                              backgroundColor: "#000",
                              color: "#fff",
                              height: 30,
                              width: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 6,
                            }}
                          >
                            <FontAwesomeIcon icon={faPhone} size="20" />
                          </div>
                        </a>

                        <a
                          href={`sms:${husbandHostPhone}`}
                          style={{ display: "inline-block", padding: "0 5px" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              display: "inline-block",
                              backgroundColor: "#000",
                              color: "#fff",
                              height: 30,
                              width: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 6,
                            }}
                          >
                            <FontAwesomeIcon icon={faSms} size="20" />
                          </div>
                        </a>
                      </div>
                    </div>
                  ) : (
                    false
                  )}

                  {wifeHostPhone !== "" ? (
                    <div style={{ display: "inline-block", flex: 1 }}>
                      <p>{basic_5}</p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href={`tel:${wifeHostPhone}`}
                          style={{ display: "inline-block", padding: "0 5px" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              display: "inline-block",
                              backgroundColor: "#000",
                              color: "#fff",
                              height: 30,
                              width: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 6,
                            }}
                          >
                            <FontAwesomeIcon icon={faPhone} size="20" />
                          </div>
                        </a>

                        <a
                          href={`sms:${wifeHostPhone}`}
                          style={{ display: "inline-block", padding: "0 5px" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              display: "inline-block",
                              backgroundColor: "#000",
                              color: "#fff",
                              height: 30,
                              width: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 6,
                            }}
                          >
                            <FontAwesomeIcon icon={faSms} size="20" />
                          </div>
                        </a>
                      </div>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              )}

              {/* 혼주 연락처 */}
            </div>
          </div>
          <button
            id="kakao-link-btn"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              border: "none",
              background: "#333",
              color: "#f9f9f9",
              width: "100%",
              fontSize: 16,
              height: 60,
              fontFamily: "Baloo 2",
              fontWeight: "600",
            }}
          >
            카카오톡 공유하기
          </button>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default CustomChewyul;
