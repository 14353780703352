import React from "react";
import kakaoImg from "../../assets/images/kakaopay.png";
import {
  faPhoneAlt,
  faEnvelope,
  faShareAlt,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAccount from "../../components/ModalAccount.js/ModalAccount";

export default function CallComponent(props) {
  return (
    <div>
      {props.husbandPhone === "" &&
      props.wifePhone === "" &&
      props.husbandKakaoPay === "" &&
      props.wifeKakaoPay === "" ? (
        false
      ) : (
        <div
          data-aos={props.isSharu ? "fade-up" : ""}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 30,
            fontSize: 14,
            paddingRight: 20,
            paddingLeft: 20,
            fontWeight: 500,
          }}
        >
          {props.husbandPhone === "" && props.husbandKakaoPay === "" ? (
            false
          ) : (
            <div style={{ flex: 1 }}>
              <p>{props.basic_2}</p>
              {props.husbandPhone !== "" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 18,
                    color: "#333",
                  }}
                >
                  <a
                    href={`tel:${props.husbandPhone}`}
                    style={{
                      padding: "3px 8px",
                      marginRight: 10,
                      color: "#333",
                    }}
                  >
                    <FontAwesomeIcon icon={faPhoneAlt} size="14" />
                  </a>
                  <a
                    href={`sms:${props.husbandPhone}`}
                    style={{ padding: "3px 8px", color: "#333" }}
                  >
                    <FontAwesomeIcon icon={faEnvelope} size="14" />
                  </a>
                </div>
              ) : (
                false
              )}

              <div
                style={{
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {props.path === "account" && props.accountVer === 2 ? (
                  props.husbandBank === "" ? (
                    false
                  ) : (
                    <ModalAccount
                      modalFont={props.modalFont}
                      basic={props.basic_6}
                      bank={props.husbandBank}
                      account={props.husbandAccount}
                      name={props.husbandAccountName}
                    />
                  )
                ) : (
                  false
                )}

                {props.id === "OHPe4iXrf2K8jAISRvaz" &&
                props.path === "none" ? (
                  <ModalAccount
                    modalFont={props.modalFont}
                    basic={props.basic_6}
                    bank={props.husbandBank}
                    account={props.husbandAccount}
                    name={props.husbandAccountName}
                  />
                ) : (
                  false
                )}

                {props.husbandKakaoPay === "" || props.path === "kakao" ? (
                  false
                ) : (
                  <div style={{ display: "inline-block" }}>
                    <a
                      href={`https://qr.kakaopay.com/${props.husbandKakaoPay}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={kakaoImg}
                        style={{
                          width: 50,
                          borderRadius: 50,
                          padding: "0 5px",
                        }}
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}

          {props.wifePhone === "" && props.wifeKakaoPay === "" ? (
            false
          ) : (
            <div style={{ flex: 1 }}>
              <p>{props.basic_3}</p>
              {props.wifePhone !== "" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 18,
                    color: "#333",
                  }}
                >
                  <a
                    href={`tel:${props.wifePhone}`}
                    style={{
                      padding: "3px 8px",
                      marginRight: 10,
                      color: "#333",
                    }}
                  >
                    <FontAwesomeIcon icon={faPhoneAlt} size="14" />
                  </a>
                  <a
                    href={`sms:${props.wifePhone}`}
                    style={{ padding: "3px 8px", color: "#333" }}
                  >
                    <FontAwesomeIcon icon={faEnvelope} size="14" />
                  </a>
                </div>
              ) : (
                false
              )}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 15,
                }}
              >
                {props.path === "account" && props.accountVer === 2 ? (
                  props.wifeBank === "" ? (
                    false
                  ) : (
                    <ModalAccount
                      modalFont={props.modalFont}
                      basic={props.basic_7}
                      bank={props.wifeBank}
                      account={props.wifeAccount}
                      name={props.wifeAccountName}
                    />
                  )
                ) : (
                  false
                )}

                {props.id === "OHPe4iXrf2K8jAISRvaz" &&
                props.path === "none" ? (
                  <ModalAccount
                    modalFont={props.modalFont}
                    basic={props.basic_7}
                    bank={props.wifeBank}
                    account={props.wifeAccount}
                    name={props.wifeAccountName}
                  />
                ) : (
                  false
                )}

                {props.wifeKakaoPay === "" || props.path === "kakao" ? (
                  false
                ) : (
                  <div style={{ display: "inline-block" }}>
                    <a
                      href={`https://qr.kakaopay.com/${props.wifeKakaoPay}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={kakaoImg}
                        style={{
                          width: 50,
                          borderRadius: 50,
                          padding: "0 5px",
                        }}
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
