import React, { useState, useEffect } from "react";
import AppRouter from "../routes/Router";
import { authService } from "../fbase";

function App() {
  const [init, setInit] = useState(false);
  const [userObj, setUserObj] = useState(null);

  useEffect(() => {
    authService.onAuthStateChanged((user) => {
      if (user) {
        setUserObj({
          displayName: user.displayName,
          uid: user.uid,
          updateProfile: (args) => user.updateProfile(args),
        });
      } else {
        setUserObj(null);
        console.log("null");
      }
      setInit(true);
    });
  }, []);

  return (
    // <>{init ? <AppRouter isLoggedIn={Boolean(userObj)} /> : <div></div>}</>
    <AppRouter isLoggedIn={Boolean(userObj)} />
  );
}

export default App;
