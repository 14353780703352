import React from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

export default function AccountComponent(props) {
  return (
    <div style={{}}>
      <p
        data-aos={props.isSharu ? "fade-up" : ""}
        style={{ fontSize: 14, fontWeight: 500 }}
      >
        {ReactHtmlParser(props.basic_1)}
      </p>
      {(props.husbandBank == "" && props.wifeBank == "") ||
      props.path !== "account" ||
      props.accountVer !== 1 ? (
        false
      ) : (
        <div
          data-aos={props.isSharu ? "fade-up" : ""}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            margin: "0 20px",
            marginTop: 30,
            padding: "13px 0 13px",
            fontWeight: 500,
            fontSize: 14,
            border: "1px solid #333",
            borderRadius: 5,
          }}
        >
          {props.husbandAccountName != "" ? (
            <div
              style={{
                flex: 1,
                borderRight:
                  props.wifeAccountName === "" ? "none" : "1px solid #333",
              }}
            >
              <div style={{}}>
                <span
                  style={{
                    padding: "0 6px 6px",
                    borderBottom: "1px solid #333",
                    fontSize: 14,
                  }}
                >
                  {ReactHtmlParser(props.basic_6)}
                </span>
              </div>
              <div style={{ fontSize: 12, lineHeight: "14px" }}>
                <div style={{ marginTop: 14 }}>
                  {ReactHtmlParser(props.husbandBank)}
                </div>
                <div>{ReactHtmlParser(props.husbandAccount)}</div>
                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                  예금주 : {ReactHtmlParser(props.husbandAccountName)}
                </div>
              </div>
            </div>
          ) : (
            false
          )}
          {props.wifeAccountName != "" ? (
            <div style={{ flex: 1 }}>
              <div style={{}}>
                <span
                  style={{
                    padding: "0 6px 6px",
                    borderBottom: "1px solid #333",
                    fontSize: 14,
                  }}
                >
                  {ReactHtmlParser(props.basic_7)}
                </span>
              </div>
              <div style={{ fontSize: 12, lineHeight: "14px" }}>
                <div style={{ marginTop: 14 }}>
                  {ReactHtmlParser(props.wifeBank)}
                </div>
                <div>{ReactHtmlParser(props.wifeAccount)}</div>
                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                  예금주 : {ReactHtmlParser(props.wifeAccountName)}
                </div>
              </div>
            </div>
          ) : (
            false
          )}
        </div>
      )}
    </div>
  );
}
