import React, { useState, useEffect } from "react";
import wedding_music from "../../assets/music/wedding_music.mp3";
import Lottie from "react-lottie";
import music from "../../assets/lotties/music.json";
import { faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const audio = new Audio(wedding_music);
audio.addEventListener(
  "ended",
  function () {
    this.currentTime = 0;
    this.play();
  },
  false
);

const agt = navigator.userAgent.toLowerCase();

export default function HeaderMusic(props) {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [iconPosition, setIconPosition] = useState("-50px");
  const [isPaused, setIsPaused] = useState(true);

  const defaultOption = {
    loop: true,
    autoplay: true,
    animationData: music,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    console.log(agt);
    setHeaderHeight(30);
    setIconPosition("-25px");
    setTimeout(() => {
      setHeaderHeight(0);

      if (props.autoPlay === true && agt.indexOf("kakaotalk") != -1) {
        audio.play();
        setIsPaused(false);
      }
    }, 3000);
  }, []);
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          maxWidth: 480,
          fontSize: 12,
          backgroundColor: "rgba(0,0,0,0.3)",
          transition: "height 1s",
          height: headerHeight,
          color: "#f9f9f9",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
      >
        <span style={{ display: headerHeight === 0 ? "none" : "inline" }}>
          배경음악이 준비되었습니다.
        </span>
      </div>
      <div
        style={{
          position: "fixed",
          top: 0,
          color: "#fff",
          width: "100%",
          fontSize: 12,
          maxWidth: 480,
          zIndex: "10000",
          textAlign: "right",
        }}
      >
        <div
          style={{
            position: "relative",
            top: iconPosition,
            display: "inline-block",
            transition: "top 1s",
          }}
          onClick={() => {
            console.log(isPaused);
            setIsPaused(!isPaused);

            isPaused ? audio.play() : audio.pause();
          }}
        >
          {isPaused ? (
            <div
              style={{
                fontSize: 18,
                width: 50,
                height: 80,
                display: "inline-block",
                color: "#000",
                paddingTop: props.paddingTop,
                paddingRight: 15,
                opacity: 0.5,
              }}
            >
              <FontAwesomeIcon icon={faVolumeMute} size="20" />
            </div>
          ) : (
            <Lottie options={defaultOption} height={80} width={50} />
          )}
        </div>
      </div>
    </>
  );
}
