import React from "react";
import Lottie from "react-lottie";
import loading from "../../assets/lotties/loading.json";

const Loading = () => {
  const defaultOption = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie options={defaultOption} height={100} width={100} />
    </div>
  );
};

export default Loading;
