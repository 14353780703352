import React, { useState, useEffect } from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { dbService } from "../../../fbase";
import "./White.css";
import Snowfall from "react-snowfall";

import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import {
  faMapMarkerAlt,
  faMobileAlt,
  faShare,
  faSms,
  faPhone,
  faPhoneAlt,
  faEnvelope,
  faShareAlt,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment, { Moment as MomentTypes } from "moment";
import CustomMap from "../../../components/CustomMap/CustomMap";
import HeaderMusic from "../../../components/HeaderMusic/HeaderMusic";

import kakaoImg from "../../../assets/images/kakaopay.png";
import flowerImg_1 from "../../../assets/images/flower.png";
import flowerImg_2 from "../../../assets/images/flower2.png";
import banner from "../../../assets/images/banner.png";

import { useParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Loading from "../../Loading/Loading";
import AccountComponent from "../../../components/AccountComponent/AccountComponent";
import CallComponent from "../../../components/CallCompnent/CallComponent";
import ParentAccountComponent from "../../../components/ParentAccountComponent/ParentAccountComponent";
import ParentCallComponent from "../../../components/ParentCallComponent/ParentCallComponent";
import ArrowCompnent from "../../../components/ArrowComponent/ArrowComponent";

const transMon = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

const ReWhite21 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isImgLoading, setIsImgLoading] = useState(false);
  const [isSharu, setIsSharu] = useState(false);
  const [isAnime, setIsAnime] = useState(false);
  const [isMusic, setIsMusic] = useState(false);
  const [isGuide, setIsGuide] = useState(false);

  const [isSlide, setIsSlide] = useState(false);

  const [autoPlay, setAutoPlay] = useState(false);

  const [accountVer, setAccountVer] = useState(1);
  const [animeVer, setAnimeVer] = useState(1);

  const [husbandName, setHusbandName] = useState("");
  const [husbandShortName, setHusbandShortName] = useState("");
  const [husbandPhone, setHusbandPhone] = useState("");
  const [husbandKakaoPay, setHusbandKakaoPay] = useState("");
  const [husbandPapa, setHusbandPapa] = useState("");
  const [husbandMama, setHusbandMama] = useState("");
  const [husbandFirstSon, setHusbandFirstSon] = useState("");
  const [husbandAccount, setHusbandAccount] = useState("");
  const [husbandBank, setHusbandBank] = useState("");
  const [husbandAccountName, setHusbandAccountName] = useState("");
  const [husbandHostPhone, setHusbandHostPhone] = useState("");

  const [wifeName, setWifeName] = useState("");
  const [wifeShortName, setWifeShortName] = useState("");
  const [wifePhone, setWifePhone] = useState("");
  const [wifeKakaoPay, setWifeKakaoPay] = useState("");
  const [wifePapa, setWifePapa] = useState("");
  const [wifeMama, setWifeMama] = useState("");
  const [wifeFirstDau, setWifeFirstDau] = useState("");
  const [wifeAccount, setWifeAccount] = useState("");
  const [wifeBank, setWifeBank] = useState("");
  const [wifeAccountName, setWifeAccountName] = useState("");
  const [wifeHostPhone, setWifeHostPhone] = useState("");

  const [husbandPapaKakao, setHusbandPapaKakao] = useState("");
  const [husbandMamaKakao, setHusbandMamaKakao] = useState("");
  const [wifePapaKakao, setWifePapaKakao] = useState("");
  const [wifeMamaKakao, setWifeMamaKakao] = useState("");

  const [weddingDate, setWeddingDate] = useState("");
  const [weddingLocation_1, setWeddingLocation_1] = useState("");
  const [weddingLocation_2, setWeddingLocation_2] = useState("");
  const [weddingNumber, setWeddingNumber] = useState("");
  const [weddingHow, setWeddingHow] = useState("");
  const [weddingAddress, setWeddingAddress] = useState("");
  const [invatationContent, setInvatationContent] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [kakaoTitle, setKakaoTitle] = useState("");

  const [basic_1, setBasic_1] = useState("");
  const [basic_2, setBasic_2] = useState("");
  const [basic_3, setBasic_3] = useState("");
  const [basic_4, setBasic_4] = useState("");
  const [basic_5, setBasic_5] = useState("");
  const [basic_6, setBasic_6] = useState("");
  const [basic_7, setBasic_7] = useState("");

  const [basic_8, setBasic_8] = useState("");
  const [basic_9, setBasic_9] = useState("");

  const [basic_10, setBasic_10] = useState("");
  const [basic_11, setBasic_11] = useState("");

  const [basic_12, setBasic_12] = useState("");
  const [basic_13, setBasic_13] = useState("");

  const [basic_14, setBasic_14] = useState("");
  const [basic_15, setBasic_15] = useState("");
  const [basic_16, setBasic_16] = useState("");

  const [modalFont, setModalFont] = useState("");

  const [galleryText, setGalleryText] = useState("");

  const [optionLeftName, setOptionLeftName] = useState("");
  const [optionLeftBank, setOptionLeftBank] = useState("");
  const [optionLeftAccount, setOptionLeftAccount] = useState("");

  const [optionRightName, setOptionRightName] = useState("");
  const [optionRightBank, setOptionRightBank] = useState("");
  const [optionRightAccount, setOptionRightAccount] = useState("");

  const [optionBottomLeftName, setOptionBottomLeftName] = useState("");
  const [optionBottomLeftBank, setOptionBottomLeftBank] = useState("");
  const [optionBottomLeftAccount, setOptionBottomLeftAccount] = useState("");

  const [optionBottomRightName, setOptionBottomRightName] = useState("");
  const [optionBottomRightBank, setOptionBottomRightBank] = useState("");
  const [optionBottomRightAccount, setOptionBottomRightAccount] = useState("");

  const [optionHusbandMama, setOptionHusbandMama] = useState("");
  const [optionWifeMama, setOptionWifeMama] = useState("");

  const [mainImg, setMainImg] = useState("");
  const [thumbImg, setThumbImg] = useState("");

  const [galleryImg_1, setGalleryImg_1] = useState("");
  const [galleryImg_2, setGalleryImg_2] = useState("");
  const [galleryImg_3, setGalleryImg_3] = useState("");
  const [galleryImg_4, setGalleryImg_4] = useState("");
  const [galleryImg_5, setGalleryImg_5] = useState("");
  const [galleryImg_6, setGalleryImg_6] = useState("");
  const [galleryImg_7, setGalleryImg_7] = useState("");
  const [galleryImg_8, setGalleryImg_8] = useState("");
  const [galleryImg_9, setGalleryImg_9] = useState("");
  const [galleryImg_10, setGalleryImg_10] = useState("");

  const [galleryArr, setGalleryArr] = useState([]);

  const { id, path } = useParams();

  const params = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    renderPrevButton: () => (
      <FontAwesomeIcon
        icon={faChevronLeft}
        className="swiper-button-prev"
        size="20"
      />
    ),
    renderNextButton: () => (
      <FontAwesomeIcon
        icon={faChevronRight}
        className="swiper-button-next"
        size="20"
      />
    ),
    on: {
      slideChange: () => {
        setIsSlide(true);
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  };

  const getUser = async () => {
    const dbUser = await dbService.collection("users").doc(id).get();

    const data = await dbUser.data();
    setIsSharu(data.isSharu);
    setHusbandName(data.husbandName);
    setHusbandShortName(data.husbandShortName);
    setHusbandPhone(data.husbandPhone);
    setHusbandKakaoPay(data.husbandKakaoPay);
    setHusbandPapa(data.husbandPapa);
    setHusbandMama(data.husbandMama);
    setHusbandFirstSon(data.husbandFirstSon);
    setHusbandAccount(data.husbandAccount);
    setHusbandBank(data.husbandBank);
    setHusbandAccount(data.husbandAccount);
    setHusbandAccountName(data.husbandAccountName);
    setHusbandHostPhone(data.husbandHostPhone);

    setWifeName(data.wifeName);
    setWifeShortName(data.wifeShortName);
    setWifePhone(data.wifePhone);
    setWifeKakaoPay(data.wifeKakaoPay);
    setWifePapa(data.wifePapa);
    setWifeMama(data.wifeMama);
    setWifeFirstDau(data.wifeFirstDau);
    setWifeAccount(data.wifeAccount);
    setWifeBank(data.wifeBank);
    setWifeAccountName(data.wifeAccountName);
    setWifeHostPhone(data.wifeHostPhone);

    setWeddingDate(data.weddingDate);
    setWeddingLocation_1(data.weddingLocation_1);
    setWeddingLocation_2(data.weddingLocation_2);
    setWeddingNumber(data.weddingNumber);
    setWeddingHow(data.weddingHow);
    setWeddingAddress(data.weddingAddress);
    setInvatationContent(data.invatationContent);
    setLongitude(data.longitude);
    setLatitude(data.latitude);
    setKakaoTitle(data.kakaoTitle);

    setBasic_1(data.basic_1);
    setBasic_2(data.basic_2);
    setBasic_3(data.basic_3);
    setBasic_4(data.basic_4);
    setBasic_5(data.basic_5);
    setBasic_6(data.basic_6);
    setBasic_7(data.basic_7);
    if (data.isAnime) {
      setIsAnime(data.isAnime);
    }
    if (data.basic_8) {
      setBasic_8(data.basic_8);
    }

    if (data.basic_9) {
      setBasic_9(data.basic_9);
    }

    if (data.basic_10) {
      setBasic_10(data.basic_10);
    }

    if (data.basic_11) {
      setBasic_11(data.basic_11);
    }

    if (data.basic_12) {
      setBasic_12(data.basic_12);
    }

    if (data.basic_13) {
      setBasic_13(data.basic_13);
    }

    if (data.basic_14) {
      setBasic_14(data.basic_14);
    }

    if (data.basic_15) {
      setBasic_15(data.basic_15);
    }

    if (data.basic_16) {
      setBasic_16(data.basic_16);
    }

    if (data.optionLeftAccount) {
      setOptionLeftAccount(data.optionLeftAccount);
    }

    if (data.optionLeftName) {
      setOptionLeftName(data.optionLeftName);
    }

    if (data.optionLeftBank) {
      setOptionLeftBank(data.optionLeftBank);
    }

    if (data.optionRightAccount) {
      setOptionRightAccount(data.optionRightAccount);
    }

    if (data.optionRightName) {
      setOptionRightName(data.optionRightName);
    }

    if (data.optionRightBank) {
      setOptionRightBank(data.optionRightBank);
    }

    if (data.optionBottomLeftAccount) {
      setOptionBottomLeftAccount(data.optionBottomLeftAccount);
    }

    if (data.optionBottomLeftName) {
      setOptionBottomLeftName(data.optionBottomLeftName);
    }

    if (data.optionBottomLeftBank) {
      setOptionBottomLeftBank(data.optionBottomLeftBank);
    }

    if (data.optionBottomRightAccount) {
      setOptionBottomRightAccount(data.optionBottomRightAccount);
    }

    if (data.optionBottomRightName) {
      setOptionBottomRightName(data.optionBottomRightName);
    }

    if (data.optionBottomRightBank) {
      setOptionBottomRightBank(data.optionBottomRightBank);
    }

    if (data.optionHusbandMama) {
      setOptionHusbandMama(data.optionHusbandMama);
    }

    if (data.optionWifeMama) {
      setOptionWifeMama(data.optionWifeMama);
    }

    if (data.isMusic) {
      setIsMusic(data.isMusic);
    }
    if (data.isGuide) {
      setIsGuide(data.isGuide);
    }
    if (data.autoPlay) {
      setAutoPlay(data.autoPlay);
    }

    if (data.accountVer) {
      setAccountVer(data.accountVer);
    }

    if (data.animeVer) {
      setAnimeVer(data.animeVer);
    }

    if (data.galleryText) {
      setGalleryText(data.galleryText);
    }

    if (data.modalFont) {
      setModalFont(data.modalFont);
    }
    if (data.husbandPapaKakao) {
      setHusbandPapaKakao(data.husbandPapaKakao);
    }

    if (data.husbandMamaKakao) {
      setHusbandMamaKakao(data.husbandMamaKakao);
    }

    if (data.wifePapaKakao) {
      setWifePapaKakao(data.wifePapaKakao);
    }

    if (data.wifeMamaKakao) {
      setWifeMamaKakao(data.wifeMamaKakao);
    }

    setMainImg(data.downloadUrlMain);
    setGalleryArr(
      [
        data.galleryUrl_1 !== "" && data.galleryUrl_1,
        data.galleryUrl_2 !== "" && data.galleryUrl_2,
        data.galleryUrl_3 !== "" && data.galleryUrl_3,
        data.galleryUrl_4 !== "" && data.galleryUrl_4,
        data.galleryUrl_5 !== "" && data.galleryUrl_5,
        data.galleryUrl_6 !== "" && data.galleryUrl_6,
        data.galleryUrl_7 !== "" && data.galleryUrl_7,
        data.galleryUrl_8 !== "" && data.galleryUrl_8,
        data.galleryUrl_9 !== "" && data.galleryUrl_9,
        data.galleryUrl_10 !== "" && data.galleryUrl_10,
        data.galleryUrl_11 !== "" &&
          data.galleryUrl_11 !== undefined &&
          data.galleryUrl_11,
        data.galleryUrl_12 !== "" &&
          data.galleryUrl_12 !== undefined &&
          data.galleryUrl_12,
        data.galleryUrl_13 !== "" &&
          data.galleryUrl_13 !== undefined &&
          data.galleryUrl_13,
        data.galleryUrl_14 !== "" &&
          data.galleryUrl_14 !== undefined &&
          data.galleryUrl_14,
        data.galleryUrl_15 !== "" &&
          data.galleryUrl_15 !== undefined &&
          data.galleryUrl_15,
        data.galleryUrl_16 !== "" &&
          data.galleryUrl_16 !== undefined &&
          data.galleryUrl_16,
        data.galleryUrl_17 !== "" &&
          data.galleryUrl_17 !== undefined &&
          data.galleryUrl_17,
        data.galleryUrl_18 !== "" &&
          data.galleryUrl_18 !== undefined &&
          data.galleryUrl_18,
        data.galleryUrl_19 !== "" &&
          data.galleryUrl_19 !== undefined &&
          data.galleryUrl_19,
        data.galleryUrl_20 !== "" &&
          data.galleryUrl_20 !== undefined &&
          data.galleryUrl_20,
      ].filter(arrayFilter)
    );
    console.log("222");

    await setIsLoading(true);

    if (data.weddingAddress !== "") {
      await window.Kakao.Link.createDefaultButton({
        container: "#kakao-link-btn",
        objectType: "location",
        address: data.kakaoAddress ? data.kakaoAddress : data.weddingAddress,
        content: {
          title: data.kakaoTitle.split("<br/>").join("\n"),
          description: "",
          imageUrl: data.thumbImg ? data.thumbImg : data.downloadUrlMain,
          imageHeight: 800,
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
            androidExecParams: "invite",
          },
        },
        buttons: [
          {
            title: "모바일청첩장",
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          },
        ],
      });
    } else {
      await window.Kakao.Link.createDefaultButton({
        container: "#kakao-link-btn",
        objectType: "feed",
        content: {
          title: data.kakaoTitle.split("<br/>").join("\n"),
          description: "",
          imageUrl: data.thumbImg ? data.thumbImg : data.downloadUrlMain,
          imageHeight: 800,
          link: {
            mobileWebUrl: window.location.href,
            androidExecParams: "invite",
          },
        },
        buttons: [
          {
            title: "모바일청첩장",
            link: {
              mobileWebUrl: window.location.href,
            },
          },
        ],
      });
    }
  };

  const generate = () => {
    const today = moment(weddingDate.slice(0, 10));
    const startWeek = today.clone().startOf("month").week();
    const endWeek =
      today.clone().endOf("month").week() === 1
        ? 53
        : today.clone().endOf("month").week();
    let calendar = [];
    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <div className="calendar-height-premium" key={week}>
          {Array(7)
            .fill(0)
            .map((n, i) => {
              let current = today
                .clone()
                .week(week)
                .startOf("week")
                .add(n + i, "day");
              let isSelected =
                today.format("YYYYMMDD") === current.format("YYYYMMDD")
                  ? "selected-premium"
                  : "";
              let isGrayed =
                current.format("MM") === today.format("MM")
                  ? ""
                  : "grayed-premium";
              return (
                <div
                  className={`calendar-division-premium calendar-num-premium `}
                  key={i}
                >
                  <span className={`${isSelected} ${isGrayed}`}>
                    {current.format("D")}
                  </span>
                </div>
              );
            })}
        </div>
      );
    }
    return calendar;
  };

  const dday = (day) => {
    var Dday = new Date(day);
    var now = new Date();

    var gap = now.getTime() - Dday.getTime();
    var result = Math.floor(gap / (1000 * 60 * 60 * 24)) * -1 - 1;
    return result;
  };

  function checkBatchimEnding(word) {
    if (typeof word !== "string") return null;

    var lastLetter = word[word.length - 1];
    var uni = lastLetter.charCodeAt(0);

    if (uni < 44032 || uni > 55203) return null;

    return (uni - 44032) % 28 != 0;
  }

  const translateDate = (abc) => {
    var weekday = new Array();
    weekday[0] = "일요일";
    weekday[1] = "월요일";
    weekday[2] = "화요일";
    weekday[3] = "수요일";
    weekday[4] = "목요일";
    weekday[5] = "금요일";
    weekday[6] = "토요일";

    var date = new Date(abc.slice(0, 10) + "T00:00:00Z");
    var year = date.getFullYear();
    var month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1).toString()
        : date.getMonth() + 1;
    var day =
      date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate();
    var hours =
      abc.slice(11, 13) < 10
        ? "오전 0" + abc.slice(11, 13).toString() + "시"
        : abc.slice(11, 13) > 12
        ? "오후 " + (abc.slice(11, 13) - 12).toString() + "시"
        : abc.slice(11, 13) == 12
        ? "오후 " + abc.slice(11, 13).toString() + "시"
        : "오전 " + abc.slice(11, 13).toString() + "시";
    var min = abc.slice(14, 16) == 0 ? "" : abc.slice(14, 16) + "분";
    var week = date.getDay();

    return `${year}.${month}.${day} ${weekday[week]} ${hours} ${min}`;
  };

  const translateDateEng = (abc) => {
    var weekday = new Array();
    weekday[0] = "SUN";
    weekday[1] = "MON";
    weekday[2] = "TUE";
    weekday[3] = "WED";
    weekday[4] = "THU";
    weekday[5] = "FRI";
    weekday[6] = "SAT";

    var date = new Date(abc.slice(0, 10) + "T00:00:00Z");
    var year = date.getFullYear();
    var month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1).toString()
        : date.getMonth() + 1;
    var day =
      date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate();
    var hours =
      abc.slice(11, 13) < 10
        ? "0" + abc.slice(11, 13)
        : abc.slice(11, 13) > 12
        ? abc.slice(11, 13) - 12
        : abc.slice(11, 13);
    var min =
      abc.slice(14, 16) < 10
        ? abc.slice(11, 13) >= 12
          ? abc.slice(14, 16).toString() + "PM"
          : abc.slice(14, 16).toString() + "AM"
        : abc.slice(11, 13) >= 12
        ? abc.slice(14, 16) + "PM"
        : abc.slice(14, 16) + "AM";
    var week = date.getDay();

    return `${year}.${month}.${day} ${weekday[week]} ${hours}:${min}`;
  };

  function arrayFilter(value) {
    return value !== false;
  }

  useEffect(() => {
    getUser();
    console.log("11111");
    window.Kakao.init("f9e17c5a4de61c4456e235636385c6b9");

    console.log(id);
    console.log(path);
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          className="white"
          style={{
            fontFamily: "NanumSquare",
            position: "relative",
            backgroundColor: "#111",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isAnime ? (
            animeVer === 1 ? (
              <Snowfall
                // Changes the snowflake color
                color="#fefefe"
                // Applied to the canvas element
                // Controls the number of snowflakes that are created (default 150)
                snowflakeCount={300}
                style={{ zIndex: 1000 }}
              />
            ) : animeVer === 2 ? (
              <div className="snow-bg"></div>
            ) : (
              false
            )
          ) : (
            false
          )}
          {isMusic ? (
            <HeaderMusic paddingTop={30} autoPlay={autoPlay} />
          ) : (
            false
          )}

          <div
            style={{
              height: "100%",
              minHeight: "100vh",
              width: "100%",
              maxWidth: 480,
              backgroundColor: "#fff",
              position: "relative",
              paddingBottom: 200,
            }}
          >
            {/* 메인 */}
            <div className="text-focus-in" style={{ position: "relative" }}>
              <div
                style={{
                  marginLeft: 16,
                  marginTop: 16,
                  width: window.outerWidth - 32,
                  maxWidth: 448,
                  height: "100%",
                  position: "absolute",
                }}
              >
                <div
                  style={{
                    border: "1px solid",
                    borderColor: "#999",
                    width: "100%",
                    height: "100%",
                  }}
                ></div>
              </div>
              <div
                style={{
                  height: isImgLoading ? "auto" : "90vh",
                }}
              >
                <img
                  src={mainImg}
                  onLoad={async () => {
                    await setIsImgLoading(true);
                    await Aos.init({ duration: 2000 });
                  }}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </div>

              <div style={{ paddingBottom: 10 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "30px 0",
                    fontSize: 20,
                    fontFamily: "Nanum Myeongjo",
                  }}
                >
                  <span style={{ color: "#999" }}>
                    {ReactHtmlParser(husbandName)}
                  </span>
                  <span style={{ padding: "0 30px", color: "#999" }}>|</span>
                  <span style={{ color: "#999" }}>
                    {ReactHtmlParser(wifeName)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#333",
                    lineHeight: "15px",
                  }}
                >
                  <span style={{ fontSize: 14 }}>
                    {translateDateEng(weddingDate)}
                  </span>

                  <span
                    style={{
                      fontSize: 12,
                      paddingTop: 2,
                    }}
                  >
                    {weddingLocation_1}
                  </span>
                </div>
              </div>
            </div>
            {/* 메인 */}

            {/* 인사말 */}
            <div className="text-focus-in" style={{ padding: "100px 0 90px" }}>
              <div
                data-aos={isSharu ? "fade-up" : ""}
                style={{ textAlign: "center", paddingBottom: 10 }}
              >
                <img src={flowerImg_1} style={{ width: 40 }} />
              </div>
              <div
                data-aos={isSharu ? "fade-up" : ""}
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  color: "#999",
                  lineHeight: "35px",
                  fontFamily: "Nanum-Garam",
                  fontWeight: "700",
                }}
              >
                {ReactHtmlParser(invatationContent)}
              </div>

              {husbandPapa === "" && husbandMama === "" ? (
                false
              ) : (
                <div
                  data-aos={isSharu ? "fade-up" : ""}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 50,
                    fontFamily: "Nanum Myeongjo",
                  }}
                >
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <p style={{ color: "#999", width: "100%" }}>
                      {ReactHtmlParser(husbandPapa)}
                      {husbandMama != "" && husbandPapa != "" ? "·" : false}
                      {ReactHtmlParser(husbandMama)}{" "}
                      <span style={{ fontSize: 12, color: "#999" }}>
                        의{" "}
                        <span style={{ width: 24, display: "inline-block" }}>
                          {husbandFirstSon}
                        </span>
                      </span>{" "}
                      <span
                        style={{
                          minWidth: 31,
                          display: "inline-block",
                          textAlign: "center",
                          color: "#333",
                        }}
                      >
                        {husbandShortName}
                      </span>
                    </p>
                    <p style={{ color: "#999" }}>
                      {ReactHtmlParser(wifePapa)}
                      {wifePapa != "" && wifeMama != "" ? "·" : false}
                      {ReactHtmlParser(wifeMama)}{" "}
                      <span style={{ fontSize: 12, color: "#999" }}>
                        의{" "}
                        <span
                          style={{
                            width: 24,
                            display: "inline-block",
                            textAlign: "center",
                            color: "#999",
                          }}
                        >
                          {wifeFirstDau}
                        </span>
                      </span>{" "}
                      <span
                        style={{
                          minWidth: 31,
                          display: "inline-block",
                          textAlign: "center",
                          color: "#333",
                        }}
                      >
                        {wifeShortName}
                      </span>
                    </p>
                  </div>
                </div>
              )}

              <div
                data-aos={isSharu ? "fade-up" : ""}
                style={{
                  textAlign: "center",
                  paddingTop: 40,
                  color: "#999",
                  fontSize: 12,
                }}
              >
                {translateDate(weddingDate)}
                <br />
                {weddingLocation_1}
              </div>
            </div>
            {/* 인사말 */}

            <div style={{}}>
              <div
                data-aos={isSharu ? "fade-up" : ""}
                style={{ paddingBottom: 40, position: "relative" }}
              >
                {isSlide === false &&
                galleryArr.length !== 0 &&
                isGuide === false ? (
                  <ArrowCompnent />
                ) : (
                  false
                )}
                <Swiper {...params}>
                  {galleryArr.map((item, index) => {
                    return (
                      <div
                        style={{ backgroundColor: "#fff", paddingBottom: 35 }}
                        key={index}
                      >
                        <img
                          src={item}
                          style={{
                            width: "100%",
                            height: 620,
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    );
                  })}
                </Swiper>
              </div>

              <div data-aos={isSharu ? "fade-up" : ""}>
                {/* <div
                className="MainName text-focus-in"
                style={{ fontSize: 20, marginBottom: 20 }}
              >
                Calendar
              </div> */}

                <div className="calendar" style={{ marginBottom: 0 }}>
                  <span className="calendar-month">
                    {
                      transMon[
                        new Date(
                          weddingDate.slice(0, 10) + "T00:00:00Z"
                        ).getMonth()
                      ]
                    }
                  </span>
                  <div>
                    <span className="calendar-division calendar-day">SUN</span>
                    <span className="calendar-division calendar-day">MON</span>
                    <span className="calendar-division calendar-day">TUE</span>
                    <span className="calendar-division calendar-day">WED</span>
                    <span className="calendar-division calendar-day">THU</span>
                    <span className="calendar-division calendar-day">FRI</span>
                    <span className="calendar-division calendar-day">SAT</span>
                  </div>
                  {generate()}
                </div>

                <div
                  style={{ backgroundColor: "#f9f9f9", marginTop: 30 }}
                  data-aos={isSharu ? "fade-up" : ""}
                >
                  <p
                    style={{
                      padding: "7px 0",
                      margin: 0,
                      fontSize: 14,
                      color: "#333",
                      fontFamily: "Noto Serif KR",
                    }}
                  >
                    {basic_15 !== "" ? basic_15 : husbandShortName}
                    {checkBatchimEnding(husbandShortName) ? "과" : "와"}{" "}
                    {basic_16 !== "" ? basic_16 : wifeShortName}의 결혼식이{" "}
                    <span style={{ fontWeight: "500" }}>
                      {dday(weddingDate.slice(0, 10) + "T15:00:00Z")}일
                    </span>{" "}
                    남았습니다.
                  </p>
                </div>
              </div>

              {/* 지도 */}
              <div
                data-aos={isSharu ? "fade-up" : ""}
                style={{
                  textAlign: "center",
                  paddingTop: 100,
                  paddingBottom: 100,
                }}
              >
                <div style={{ fontFamily: "Nanum Myeongjo" }}>
                  {latitude !== "" ? (
                    <>
                      <div
                        className="text-focus-in"
                        style={{
                          fontSize: 30,
                          marginBottom: 10,
                          color: "#333",
                        }}
                      >
                        <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                      </div>
                      <h3
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          color: "#333",
                        }}
                      >
                        {ReactHtmlParser(weddingLocation_2)}
                      </h3>
                    </>
                  ) : (
                    false
                  )}

                  {weddingAddress === "" && weddingNumber === "" ? (
                    false
                  ) : (
                    <div
                      style={{
                        paddingBottom: "3em",
                        fontSize: 12,
                        paddingLeft: 16,
                        paddingRight: 16,
                        color: "#333",
                      }}
                    >
                      {weddingAddress !== "" ? (
                        <span style={{ wordBreak: "keep-all" }}>
                          {ReactHtmlParser(weddingAddress)}
                        </span>
                      ) : (
                        false
                      )}

                      <br />
                      {weddingNumber !== "" ? (
                        <span
                          style={{ display: "inline-block", paddingTop: 6 }}
                        >
                          Tel.{weddingNumber}
                        </span>
                      ) : (
                        false
                      )}
                      <br />
                    </div>
                  )}
                </div>
                {latitude !== "" ? (
                  <CustomMap lat={latitude} lng={longitude} />
                ) : (
                  false
                )}

                <div data-aos={isSharu ? "fade-up" : ""}>
                  <div
                    className="mapRoot"
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: 20,
                      textAlign: "left",
                      fontFamily: "Noto Serif KR",
                    }}
                  >
                    {weddingHow != "undefined"
                      ? ReactHtmlParser(weddingHow)
                      : false}
                    {/* <h4>지하철</h4>
              <span>지하철 2호선 센텀시티역 4번 출구 (도보 13분)</span>

              <h4>시내버스</h4>
              <span>SK텔레콤 정류장 하차 (도보 3분)</span>
              <br />
              <span>39, 40, 63, 115, 139, 141, 155</span>
              <br />
              <span>센텀시티역 하차 (도보 13분)</span>
              <br />
              <span>181, 307, 1001, 1002</span>

              <h4>주차장</h4>
              <span>건물 지하주차장(B1F-B4F) 2시간 무료</span> */}
                  </div>
                </div>
              </div>
              {/* 지도 */}
              {/* 마음 전하실 곳 */}
              {path === "bottom" ? (
                false
              ) : (
                <>
                  <div
                    data-aos={isSharu ? "fade-up" : ""}
                    style={{ paddingBottom: 10 }}
                  >
                    <img src={flowerImg_2} style={{ width: 40, height: 40 }} />
                  </div>
                  <AccountComponent
                    isSharu={isSharu}
                    basic_1={basic_1}
                    basic_6={basic_6}
                    basic_7={basic_7}
                    husbandBank={husbandBank}
                    husbandAccountName={husbandAccountName}
                    husbandAccount={husbandAccount}
                    wifeBank={wifeBank}
                    wifeAccount={wifeAccount}
                    wifeAccountName={wifeAccountName}
                    path={path}
                    accountVer={accountVer}
                  />
                </>
              )}

              {/* 마음 전하실 곳 */}

              {/* 신랑 신부 연락하기 */}
              <CallComponent
                modalFont={modalFont}
                husbandPhone={husbandPhone}
                wifePhone={wifePhone}
                husbandKakaoPay={husbandKakaoPay}
                wifeKakaoPay={wifeKakaoPay}
                isSharu={isSharu}
                basic_2={basic_2}
                path={path}
                accountVer={accountVer}
                husbandBank={husbandBank}
                husbandAccount={husbandAccount}
                husbandAccountName={husbandAccountName}
                basic_3={basic_3}
                basic_7={basic_7}
                basic_6={basic_6}
                wifeBank={wifeBank}
                wifeAccount={wifeAccount}
                wifeAccountName={wifeAccountName}
              />
              {/* 신랑 신부 연락하기 */}

              {/* 혼주에게 연락하기 */}
              {husbandHostPhone === "" &&
              wifeHostPhone === "" &&
              optionHusbandMama === "" &&
              optionWifeMama === "" ? (
                false
              ) : (
                <div style={{ paddingTop: 70, fontSize: 14, fontWeight: 500 }}>
                  <p data-aos={isSharu ? "fade-up" : ""}>혼주에게 연락하기</p>

                  <ParentAccountComponent
                    isSharu={isSharu}
                    basic_8={basic_8}
                    basic_9={basic_9}
                    basic_10={basic_10}
                    basic_11={basic_11}
                    optionLeftBank={optionLeftBank}
                    optionLeftAccount={optionLeftAccount}
                    optionLeftName={optionLeftName}
                    optionRightBank={optionRightBank}
                    optionRightAccount={optionRightAccount}
                    optionRightName={optionRightName}
                    path={path}
                    accountVer={accountVer}
                    optionBottomLeftAccount={optionBottomLeftAccount}
                    optionBottomLeftName={optionBottomLeftName}
                    optionBottomLeftBank={optionBottomLeftBank}
                    optionBottomRightBank={optionBottomRightBank}
                    optionBottomRightAccount={optionBottomRightAccount}
                    optionBottomRightName={optionBottomRightName}
                  />

                  <ParentCallComponent
                    modalFont={modalFont}
                    isSharu={isSharu}
                    path={path}
                    accountVer={accountVer}
                    basic_8={basic_8}
                    basic_9={basic_9}
                    basic_10={basic_10}
                    basic_12={basic_12}
                    basic_11={basic_11}
                    basic_13={basic_13}
                    wifeHostPhone={wifeHostPhone}
                    optionWifeMama={optionWifeMama}
                    husbandHostPhone={husbandHostPhone}
                    optionHusbandMama={optionHusbandMama}
                    husbandMama={husbandMama}
                    husbandPapa={husbandPapa}
                    wifePapa={wifePapa}
                    wifeMama={wifeMama}
                    optionLeftBank={optionLeftBank}
                    optionLeftAccount={optionLeftAccount}
                    optionLeftName={optionLeftName}
                    optionBottomLeftBank={optionBottomLeftBank}
                    optionBottomLeftAccount={optionBottomLeftAccount}
                    optionBottomLeftName={optionBottomLeftName}
                    optionRightName={optionRightName}
                    optionRightAccount={optionRightAccount}
                    optionRightBank={optionRightBank}
                    optionBottomRightBank={optionBottomRightBank}
                    optionBottomRightAccount={optionBottomRightAccount}
                    optionBottomRightName={optionBottomRightName}
                    husbandPapaKakao={husbandPapaKakao}
                    husbandMamaKakao={husbandMamaKakao}
                    wifePapaKakao={wifePapaKakao}
                    wifeMamaKakao={wifeMamaKakao}
                  />
                </div>
              )}

              {/* 혼주에게 연락하기 */}
            </div>
            {/* footer */}
            <div
              style={{
                height: 70,
                width: "100%",
                backgroundColor: "#fefefe",
                position: "absolute",
                bottom: 0,
                borderTop: "1px solid #999",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a href="https://smartstore.naver.com/cryu">
                  <img src={banner} style={{ height: 30 }} />
                </a>
                <a
                  href="https://smartstore.naver.com/cryu"
                  style={{ textDecoration: "none" }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: 8,
                      color: "#777",
                    }}
                  >
                    Copyright © 2020 Cryucard.
                  </p>
                </a>
              </div>
              <div
                id="kakao-link-btn"
                style={{
                  position: "absolute",
                  right: 10,
                  top: 15,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    border: "1px solid #333",
                    width: 25,
                    height: 25,
                    borderRadius: 15,
                    color: "#333",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 14,
                  }}
                >
                  <FontAwesomeIcon icon={faShareAlt} size="14" />
                </div>
                <span style={{ fontSize: 8, color: "#333" }}>공유하기</span>
              </div>
            </div>
            {/* footer */}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default ReWhite21;
