import React, { useState, useEffect } from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { dbService } from "../../../fbase";
import "./Brown.css";

import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import {
  faMapMarkerAlt,
  faMobileAlt,
  faShare,
  faSms,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment, { Moment as MomentTypes } from "moment";
import CustomMap from "../../../components/CustomMap/CustomMap";
import kakaoImg from "../../../assets/images/kakaopay.png";

import { useParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Loading from "../../Loading/Loading";

const params = {
  effect: "fade",
  pagination: {
    el: ".swiper-pagination",
    clickable: false,
    dynamicBullets: true,
  },
};
const transMon = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const CustomSewoong = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSharu, setIsSharu] = useState(true);

  const [husbandName, setHusbandName] = useState("");
  const [husbandShortName, setHusbandShortName] = useState("");
  const [husbandPhone, setHusbandPhone] = useState("");
  const [husbandKakaoPay, setHusbandKakaoPay] = useState("");
  const [husbandPapa, setHusbandPapa] = useState("");
  const [husbandMama, setHusbandMama] = useState("");
  const [husbandFirstSon, setHusbandFirstSon] = useState("");
  const [husbandAccount, setHusbandAccount] = useState("");
  const [husbandBank, setHusbandBank] = useState("");
  const [husbandAccountName, setHusbandAccountName] = useState("");
  const [husbandHostPhone, setHusbandHostPhone] = useState("");

  const [wifeName, setWifeName] = useState("");
  const [wifeShortName, setWifeShortName] = useState("");
  const [wifePhone, setWifePhone] = useState("");
  const [wifeKakaoPay, setWifeKakaoPay] = useState("");
  const [wifePapa, setWifePapa] = useState("");
  const [wifeMama, setWifeMama] = useState("");
  const [wifeFirstDau, setWifeFirstDau] = useState("");
  const [wifeAccount, setWifeAccount] = useState("");
  const [wifeBank, setWifeBank] = useState("");
  const [wifeAccountName, setWifeAccountName] = useState("");
  const [wifeHostPhone, setWifeHostPhone] = useState("");

  const [weddingDate, setWeddingDate] = useState("");
  const [weddingLocation_1, setWeddingLocation_1] = useState("");
  const [weddingLocation_2, setWeddingLocation_2] = useState("");
  const [weddingNumber, setWeddingNumber] = useState("");
  const [weddingHow, setWeddingHow] = useState("");
  const [weddingAddress, setWeddingAddress] = useState("");
  const [invatationContent, setInvatationContent] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [kakaoTitle, setKakaoTitle] = useState("");

  const [basic_1, setBasic_1] = useState("");
  const [basic_2, setBasic_2] = useState("");
  const [basic_3, setBasic_3] = useState("");
  const [basic_4, setBasic_4] = useState("");
  const [basic_5, setBasic_5] = useState("");
  const [basic_6, setBasic_6] = useState("");
  const [basic_7, setBasic_7] = useState("");

  const [basic_8, setBasic_8] = useState("");
  const [basic_9, setBasic_9] = useState("");

  const [optionLeftName, setOptionLeftName] = useState("");
  const [optionLeftBank, setOptionLeftBank] = useState("");
  const [optionLeftAccount, setOptionLeftAccount] = useState("");

  const [optionRightName, setOptionRightName] = useState("");
  const [optionRightBank, setOptionRightBank] = useState("");
  const [optionRightAccount, setOptionRightAccount] = useState("");

  const [mainImg, setMainImg] = useState("");
  const [galleryImg_1, setGalleryImg_1] = useState("");
  const [galleryImg_2, setGalleryImg_2] = useState("");
  const [galleryImg_3, setGalleryImg_3] = useState("");
  const [galleryImg_4, setGalleryImg_4] = useState("");
  const [galleryImg_5, setGalleryImg_5] = useState("");
  const [galleryImg_6, setGalleryImg_6] = useState("");
  const [galleryImg_7, setGalleryImg_7] = useState("");
  const [galleryImg_8, setGalleryImg_8] = useState("");
  const [galleryImg_9, setGalleryImg_9] = useState("");
  const [galleryImg_10, setGalleryImg_10] = useState("");

  const [galleryArr, setGalleryArr] = useState([]);

  const { id, path } = useParams();

  const getUser = async () => {
    const dbUser = await dbService.collection("users").doc(id).get();

    const data = await dbUser.data();
    console.log(data);
    setIsSharu(data.isSharu);
    setHusbandName(data.husbandName);
    setHusbandShortName(data.husbandShortName);
    setHusbandPhone(data.husbandPhone);
    setHusbandKakaoPay(data.husbandKakaoPay);
    setHusbandPapa(data.husbandPapa);
    setHusbandMama(data.husbandMama);
    setHusbandFirstSon(data.husbandFirstSon);
    setHusbandAccount(data.husbandAccount);
    setHusbandBank(data.husbandBank);
    setHusbandAccount(data.husbandAccount);
    setHusbandAccountName(data.husbandAccountName);
    setHusbandHostPhone(data.husbandHostPhone);

    setWifeName(data.wifeName);
    setWifeShortName(data.wifeShortName);
    setWifePhone(data.wifePhone);
    setWifeKakaoPay(data.wifeKakaoPay);
    setWifePapa(data.wifePapa);
    setWifeMama(data.wifeMama);
    setWifeFirstDau(data.wifeFirstDau);
    setWifeAccount(data.wifeAccount);
    setWifeBank(data.wifeBank);
    setWifeAccountName(data.wifeAccountName);
    setWifeHostPhone(data.wifeHostPhone);

    setWeddingDate(data.weddingDate);
    setWeddingLocation_1(data.weddingLocation_1);
    setWeddingLocation_2(data.weddingLocation_2);
    setWeddingNumber(data.weddingNumber);
    setWeddingHow(data.weddingHow);
    setWeddingAddress(data.weddingAddress);
    setInvatationContent(data.invatationContent);
    setLongitude(data.longitude);
    setLatitude(data.latitude);
    setKakaoTitle(data.kakaoTitle);

    setBasic_1(data.basic_1);
    setBasic_2(data.basic_2);
    setBasic_3(data.basic_3);
    setBasic_4(data.basic_4);
    setBasic_5(data.basic_5);
    setBasic_6(data.basic_6);
    setBasic_7(data.basic_7);

    if (data.basic_8) {
      setBasic_8(data.basic_8);
    }

    if (data.basic_9) {
      setBasic_9(data.basic_9);
    }

    if (data.optionLeftAccount) {
      setOptionLeftAccount(data.optionLeftAccount);
    }

    if (data.optionLeftName) {
      setOptionLeftName(data.optionLeftName);
    }

    if (data.optionLeftBank) {
      setOptionLeftBank(data.optionLeftBank);
    }

    if (data.optionRightAccount) {
      setOptionRightAccount(data.optionRightAccount);
    }

    if (data.optionRightName) {
      setOptionRightName(data.optionRightName);
    }

    if (data.optionRightBank) {
      setOptionRightBank(data.optionRightBank);
    }

    setMainImg(data.downloadUrlMain);
    setGalleryArr(
      [
        data.galleryUrl_1 !== "" && data.galleryUrl_1,
        data.galleryUrl_2 !== "" && data.galleryUrl_2,
        data.galleryUrl_3 !== "" && data.galleryUrl_3,
        data.galleryUrl_4 !== "" && data.galleryUrl_4,
        data.galleryUrl_5 !== "" && data.galleryUrl_5,
        data.galleryUrl_6 !== "" && data.galleryUrl_6,
        data.galleryUrl_7 !== "" && data.galleryUrl_7,
        data.galleryUrl_8 !== "" && data.galleryUrl_8,
        data.galleryUrl_9 !== "" && data.galleryUrl_9,
        data.galleryUrl_10 !== "" && data.galleryUrl_10,
        data.galleryUrl_11 !== "" &&
          data.galleryUrl_11 !== undefined &&
          data.galleryUrl_11,
        data.galleryUrl_12 !== "" &&
          data.galleryUrl_12 !== undefined &&
          data.galleryUrl_12,
      ].filter(arrayFilter)
    );
    console.log("222");

    await setIsLoading(true);

    if (data.weddingAddress !== "") {
      await window.Kakao.Link.createDefaultButton({
        container: "#kakao-link-btn",
        objectType: "location",
        address: data.weddingAddress,
        content: {
          title: data.kakaoTitle,
          description: "",
          imageUrl: data.downloadUrlMain,
          link: {
            mobileWebUrl: window.location.href,
            androidExecParams: "invite",
          },
        },
        buttons: [
          {
            title: "모바일청첩장",
            link: {
              mobileWebUrl: window.location.href,
            },
          },
        ],
      });
    } else {
      await window.Kakao.Link.createDefaultButton({
        container: "#kakao-link-btn",
        objectType: "feed",
        content: {
          title: data.kakaoTitle,
          description: "",
          imageUrl: data.downloadUrlMain,
          link: {
            mobileWebUrl: window.location.href,
            androidExecParams: "invite",
          },
        },
        buttons: [
          {
            title: "모바일청첩장",
            link: {
              mobileWebUrl: window.location.href,
            },
          },
        ],
      });
    }
  };

  const generate = () => {
    const today = moment(weddingDate.slice(0, 10));
    const startWeek = today.clone().startOf("month").week();

    const endWeek =
      today.clone().endOf("month").week() === 1
        ? 53
        : today.clone().endOf("month").week();
    let calendar = [];
    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <div className="calendar-height" key={week}>
          {Array(7)
            .fill(0)
            .map((n, i) => {
              let current = today
                .clone()
                .week(week)
                .startOf("week")
                .add(n + i, "day");
              let isSelected =
                today.format("YYYYMMDD") === current.format("YYYYMMDD")
                  ? "selected"
                  : "";
              let isGrayed =
                current.format("MM") === today.format("MM") ? "" : "grayed";
              return (
                <div className={`calendar-division calendar-num `} key={i}>
                  <span className={`${isSelected} ${isGrayed}`}>
                    {current.format("D")}
                  </span>
                </div>
              );
            })}
        </div>
      );
    }
    return calendar;
  };

  const translateDate = (abc) => {
    var weekday = new Array();
    weekday[0] = "일요일";
    weekday[1] = "월요일";
    weekday[2] = "화요일";
    weekday[3] = "수요일";
    weekday[4] = "목요일";
    weekday[5] = "금요일";
    weekday[6] = "토요일";

    var date = new Date(abc.slice(0, 10) + "T00:00:00Z");
    var year = date.getFullYear();
    var month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1).toString()
        : date.getMonth() + 1;
    var day =
      date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate();
    var hours =
      abc.slice(11, 13) < 10
        ? "오전 0" + abc.slice(11, 13).toString() + "시"
        : abc.slice(11, 13) > 12
        ? "오후 " + (abc.slice(11, 13) - 12).toString() + "시"
        : abc.slice(11, 13) == 12
        ? "오후 " + abc.slice(11, 13).toString() + "시"
        : "오전 " + abc.slice(11, 13).toString() + "시";
    var min = abc.slice(14, 16) == 0 ? "" : abc.slice(14, 16) + "분";
    var week = date.getDay();

    return `${year}.${month}.${day} ${weekday[week]} ${hours} ${min}`;
  };

  const translateDateEng = (abc) => {
    var weekday = new Array();
    weekday[0] = "SUN";
    weekday[1] = "MON";
    weekday[2] = "TUE";
    weekday[3] = "WED";
    weekday[4] = "THU";
    weekday[5] = "FRI";
    weekday[6] = "SAT";

    var date = new Date(abc.slice(0, 10) + "T00:00:00Z");
    var year = date.getFullYear();
    var month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1).toString()
        : date.getMonth() + 1;
    var day =
      date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate();
    var hours =
      abc.slice(11, 13) < 10
        ? "0" + abc.slice(11, 13)
        : abc.slice(11, 13) > 12
        ? abc.slice(11, 13) - 12
        : abc.slice(11, 13);
    var min =
      abc.slice(14, 16) < 10
        ? abc.slice(11, 13) >= 12
          ? abc.slice(14, 16).toString() + "PM"
          : abc.slice(14, 16).toString() + "AM"
        : abc.slice(11, 13) >= 12
        ? abc.slice(14, 16) + "PM"
        : abc.slice(14, 16) + "AM";
    var week = date.getDay();

    return `${year}. ${month}. ${day} ${weekday[week]} ${hours}:${min}`;
  };
  function arrayFilter(value) {
    return value !== false;
  }

  useEffect(() => {
    getUser();
    Aos.init({ duration: 2000 });
    console.log("11111");
    window.Kakao.init("f9e17c5a4de61c4456e235636385c6b9");

    console.log(id);
    console.log(path);
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          className="brown"
          style={{
            backgroundColor: "#f2ece6",
            color: "#887f6e",
            position: "relative",
          }}
        >
          <div style={{ paddingBottom: 100 }}>
            <div style={{ paddingTop: 50, paddingBottom: 50 }}>
              <div className="text-focus-in" style={{ fontSize: 13 }}>
                Wedding Invitation
              </div>
              <div className="text-focus-in" style={{ marginTop: 30 }}>
                <span className="MainName" style={{ fontSize: 30 }}>
                  {husbandName}
                </span>{" "}
                <span
                  className="MainName"
                  style={{ fontSize: 20, paddingLeft: 10, paddingRight: 10 }}
                >
                  &amp;
                </span>{" "}
                <span className="MainName" style={{ fontSize: 30 }}>
                  {wifeName}
                </span>
              </div>
              <div className="text-focus-in">
                {translateDateEng(weddingDate)}
              </div>
            </div>

            <div className="text-focus-in">
              <img src={mainImg} style={{ width: "100%" }} />
            </div>

            <div>
              <div
                data-aos={isSharu ? "fade-up" : ""}
                className="subText text-focus-in"
                style={{ marginTop: 30 }}
              >
                <div style={{ fontSize: 20, marginBottom: 20 }}>Invitation</div>
                {ReactHtmlParser(invatationContent)}
              </div>
              {husbandPapa === "" && husbandMama === "" ? (
                false
              ) : (
                <div
                  className="MainName"
                  style={{
                    fontSize: 15,
                    paddingTop: 20,
                    paddingBottom: 20,
                    borderWidth: 1,
                    lineHeight: 2,
                  }}
                >
                  <div data-aos={isSharu ? "fade-up" : ""}>
                    <span style={{ color: "#887f6e" }}>
                      {husbandPapa}
                      {husbandMama !== "" ? " " : false}
                      {husbandMama}의 {husbandFirstSon}
                    </span>{" "}
                    {husbandShortName}
                  </div>
                  <div data-aos={isSharu ? "fade-up" : ""}>
                    <span style={{ color: "#887f6e" }}>
                      {wifePapa}
                      {wifeMama !== "" ? " " : false}
                      {wifeMama}의 {wifeFirstDau}
                    </span>{" "}
                    {wifeShortName}
                  </div>
                </div>
              )}

              <div
                data-aos={isSharu ? "fade-up" : ""}
                className="hello MainName"
              >
                {translateDate(weddingDate)}
                <br />
                {weddingLocation_1}
              </div>
            </div>

            <div
              data-aos={isSharu ? "fade-up" : ""}
              style={{ paddingBottom: 50 }}
              data-aos={isSharu ? "fade-up" : ""}
            >
              {galleryArr.length == 0 ? (
                false
              ) : (
                <div
                  className="MainName text-focus-in"
                  style={{ fontSize: 20, marginBottom: 20 }}
                >
                  Gallery
                </div>
              )}

              <Swiper {...params}>
                {galleryArr.map((item, index) => {
                  return (
                    <div
                      style={{ backgroundColor: "#f2ece6", paddingBottom: 20 }}
                      key={index}
                    >
                      <img
                        src={item}
                        style={{
                          width: "100%",
                          height: 400,
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  );
                })}
              </Swiper>
            </div>

            <div data-aos={isSharu ? "fade-up" : ""}>
              {/* <div
                className="MainName text-focus-in"
                style={{ fontSize: 20, marginBottom: 20 }}
              >
                Calendar
              </div> */}

              <div className="calendar">
                <span className="calendar-month">
                  {
                    transMon[
                      new Date(
                        weddingDate.slice(0, 10) + "T00:00:00Z"
                      ).getMonth()
                    ]
                  }
                </span>
                <div>
                  <span className="calendar-division calendar-day">SUN</span>
                  <span className="calendar-division calendar-day">MON</span>
                  <span className="calendar-division calendar-day">TUE</span>
                  <span className="calendar-division calendar-day">WED</span>
                  <span className="calendar-division calendar-day">THU</span>
                  <span className="calendar-division calendar-day">FRI</span>
                  <span className="calendar-division calendar-day">SAT</span>
                </div>
                {generate()}
              </div>
            </div>

            <div>
              {latitude !== "" ? (
                <div
                  data-aos={isSharu ? "fade-up" : ""}
                  className="MainName text-focus-in"
                  style={{ fontSize: 20, marginBottom: 10 }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />{" "}
                </div>
              ) : (
                false
              )}

              <h3
                data-aos={isSharu ? "fade-up" : ""}
                className="MainName"
                style={{ fontWeight: "bold", fontSize: "1.1em" }}
              >
                {weddingLocation_2}
              </h3>
              {weddingAddress === "" && weddingNumber === "" ? (
                false
              ) : (
                <div
                  data-aos={isSharu ? "fade-up" : ""}
                  className="MainName"
                  style={{
                    paddingBottom: "3em",
                    fontSize: "0.8em",
                    paddingLeft: 16,
                    paddingRight: 16,
                  }}
                >
                  {weddingAddress !== "" ? (
                    <span style={{ wordBreak: "keep-all" }}>
                      {ReactHtmlParser(weddingAddress)}
                    </span>
                  ) : (
                    false
                  )}
                  <br />
                  {weddingNumber !== "" ? (
                    <span>Tel.{weddingNumber}</span>
                  ) : (
                    false
                  )}

                  <br />
                </div>
              )}

              <div data-aos={isSharu ? "fade-up" : ""}>
                {latitude !== "" ? (
                  <CustomMap lat={latitude} lng={longitude} />
                ) : (
                  false
                )}
              </div>

              <div data-aos={isSharu ? "fade-up" : ""}>
                <div className="location-text">
                  {weddingHow != "undefined"
                    ? ReactHtmlParser(weddingHow)
                    : false}
                </div>
              </div>
            </div>

            <div style={{ paddingBottom: 50, paddingTop: 50 }}>
              <div data-aos={isSharu ? "fade-up" : ""}>
                <div style={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faMobileAlt} size="20" />
                </div>
                <p>{ReactHtmlParser(basic_1)}</p>
              </div>
              {path === "account" ? (
                <div
                  data-aos={isSharu ? "fade-up" : ""}
                  style={{ padding: "10px 16px 40px" }}
                >
                  <div style={{ border: "1px solid #887f6e", borderRadius: 6 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      {husbandAccountName === "" ? (
                        false
                      ) : (
                        <div
                          style={{
                            padding: "20px 8px",
                            borderRight:
                              wifeAccountName === ""
                                ? "none"
                                : "1px solid #887f6e",
                            flex: 1,
                            height: 80,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              paddingBottom: 10,
                              fontSize: 16,
                              color: "#49301e",
                              fontWeight: "bold",
                            }}
                          >
                            {basic_6}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 16 }}>
                            예금주 : {ReactHtmlParser(husbandAccountName)}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 16 }}>
                            {ReactHtmlParser(husbandBank)} :{" "}
                            {ReactHtmlParser(husbandAccount)}
                          </p>
                        </div>
                      )}
                      {wifeAccountName === "" ? (
                        false
                      ) : (
                        <div
                          style={{
                            padding: "20px 8px",
                            flex: 1,
                            height: 80,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              paddingBottom: 10,
                              fontSize: 16,
                              color: "#49301e",
                              fontWeight: "bold",
                            }}
                          >
                            {basic_7}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                            예금주 : {ReactHtmlParser(wifeAccountName)}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                            {ReactHtmlParser(wifeBank)} :{" "}
                            {ReactHtmlParser(wifeAccount)}
                          </p>
                        </div>
                      )}
                    </div>
                    {/* 계좌번호 하단 */}
                    {optionLeftAccount === "" && optionRightAccount === "" ? (
                      false
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          borderTop: "1px solid #887f6e",
                        }}
                      >
                        <div
                          style={{
                            padding: "20px 8px",
                            borderRight: "1px solid #887f6e",
                            flex: 1,
                            height: 80,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              paddingBottom: 10,
                              fontSize: 16,
                              color: "#49301e",
                              fontWeight: "bold",
                            }}
                          >
                            {basic_8}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                            예금주 : {optionLeftName}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                            {optionLeftBank} : {optionLeftAccount}
                          </p>
                        </div>
                        <div
                          style={{
                            padding: "20px 8px",
                            flex: 1,
                            height: 80,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              paddingBottom: 10,
                              fontSize: 16,
                              color: "#49301e",
                              fontWeight: "bold",
                            }}
                          >
                            {basic_9}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                            예금주 : {optionRightName}
                          </p>
                          <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                            {optionRightBank} : {optionRightAccount}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* 계좌번호 하단 */}
                  </div>
                </div>
              ) : (
                false
              )}

              <div
                data-aos={isSharu ? "fade-up" : ""}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  paddingTop: 20,
                  paddingRight: 16,
                  paddingLeft: 16,
                }}
              >
                {husbandPhone === "" ? (
                  false
                ) : (
                  <div style={{ display: "inline-block", flex: 1 }}>
                    <p>{ReactHtmlParser(basic_2)}</p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <a
                        href={`tel:${husbandPhone}`}
                        style={{ display: "inline-block", padding: "0 5px" }}
                      >
                        <div
                          style={{
                            fontSize: 20,
                            display: "inline-block",
                            backgroundColor: "#000",
                            color: "#fff",
                            height: 30,
                            width: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 6,
                          }}
                        >
                          <FontAwesomeIcon icon={faPhone} size="20" />
                        </div>
                      </a>
                      {husbandKakaoPay !== "" ? (
                        <div style={{ display: "inline-block" }}>
                          <a
                            href={`https://qr.kakaopay.com/${husbandKakaoPay}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={kakaoImg}
                              style={{
                                width: "4em",
                                borderRadius: "200px",
                                padding: "0 5px",
                              }}
                            />
                          </a>
                        </div>
                      ) : (
                        <a
                          href={`sms:${husbandPhone}`}
                          style={{ display: "inline-block", padding: "0 5px" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              display: "inline-block",
                              backgroundColor: "#000",
                              color: "#fff",
                              height: 30,
                              width: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 6,
                            }}
                          >
                            <FontAwesomeIcon icon={faSms} size="20" />
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                )}

                {wifePhone === "" ? (
                  false
                ) : (
                  <div style={{ display: "inline-block", flex: 1 }}>
                    <p>{ReactHtmlParser(basic_3)}</p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <a
                        href={`tel:${wifePhone}`}
                        style={{ display: "inline-block", padding: "0 5px" }}
                      >
                        <div
                          style={{
                            fontSize: 20,
                            display: "inline-block",
                            backgroundColor: "#000",
                            color: "#fff",
                            height: 30,
                            width: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 6,
                          }}
                        >
                          <FontAwesomeIcon icon={faPhone} size="20" />
                        </div>
                      </a>
                      {wifeKakaoPay !== "" ? (
                        <div style={{ display: "inline-block" }}>
                          <a
                            href={`https://qr.kakaopay.com/${wifeKakaoPay}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={kakaoImg}
                              style={{
                                width: "4em",
                                borderRadius: "200px",
                                padding: "0 5px",
                              }}
                            />
                          </a>
                        </div>
                      ) : (
                        <a
                          href={`sms:${wifePhone}`}
                          style={{ display: "inline-block", padding: "0 5px" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              display: "inline-block",
                              backgroundColor: "#000",
                              color: "#fff",
                              height: 30,
                              width: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 6,
                            }}
                          >
                            <FontAwesomeIcon icon={faSms} size="20" />
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* 혼주 연락처 */}

              {husbandHostPhone === "" && wifeHostPhone === "" ? (
                false
              ) : (
                <div
                  data-aos={isSharu ? "fade-up" : ""}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    paddingTop: 40,
                    paddingRight: 16,
                    paddingLeft: 16,
                  }}
                >
                  {husbandHostPhone !== "" ? (
                    <div style={{ display: "inline-block", flex: 1 }}>
                      <p>{ReactHtmlParser(basic_4)}</p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href={`tel:${husbandHostPhone}`}
                          style={{ display: "inline-block", padding: "0 5px" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              display: "inline-block",
                              backgroundColor: "#000",
                              color: "#fff",
                              height: 30,
                              width: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 6,
                            }}
                          >
                            <FontAwesomeIcon icon={faPhone} size="20" />
                          </div>
                        </a>

                        <a
                          href={`sms:${husbandHostPhone}`}
                          style={{ display: "inline-block", padding: "0 5px" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              display: "inline-block",
                              backgroundColor: "#000",
                              color: "#fff",
                              height: 30,
                              width: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 6,
                            }}
                          >
                            <FontAwesomeIcon icon={faSms} size="20" />
                          </div>
                        </a>
                      </div>
                    </div>
                  ) : (
                    false
                  )}

                  {wifeHostPhone !== "" ? (
                    <div style={{ display: "inline-block", flex: 1 }}>
                      <p>{basic_5}</p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href={`tel:${wifeHostPhone}`}
                          style={{ display: "inline-block", padding: "0 5px" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              display: "inline-block",
                              backgroundColor: "#000",
                              color: "#fff",
                              height: 30,
                              width: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 6,
                            }}
                          >
                            <FontAwesomeIcon icon={faPhone} size="20" />
                          </div>
                        </a>

                        <a
                          href={`sms:${wifeHostPhone}`}
                          style={{ display: "inline-block", padding: "0 5px" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              display: "inline-block",
                              backgroundColor: "#000",
                              color: "#fff",
                              height: 30,
                              width: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 6,
                            }}
                          >
                            <FontAwesomeIcon icon={faSms} size="20" />
                          </div>
                        </a>
                      </div>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              )}

              {/* 혼주 연락처 */}
            </div>
          </div>
          <button
            id="kakao-link-btn"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              border: "none",
              background: "rgb(255 223 0)",
              color: "#000",
              width: "100%",
              fontSize: 16,
              height: 60,
              fontFamily: "Baloo 2",
              fontWeight: "600",
            }}
          >
            카카오톡 공유하기
          </button>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default CustomSewoong;
