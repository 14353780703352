import React, { Component } from "react";
import { RenderAfterNavermapsLoaded, NaverMap, Marker } from "react-naver-maps";

function CustomMap(props) {
  return (
    <RenderAfterNavermapsLoaded
      ncpClientId={"g25a49xfi2"} // 자신의 네이버 계정에서 발급받은 Client ID
      error={<p>Maps Load Error</p>}
      loading={<p>Maps Loading...</p>}
    >
      <NaverMap
        mapDivId={"maps-getting-started-uncontrolled"} // default: react-naver-map
        style={{
          width: "100%", // 네이버지도 가로 길이
          height: "40vh", // 네이버지도 세로 길이
        }}
        defaultCenter={{ lat: props.lat, lng: props.lng }} // 지도 초기 위치
        defaultZoom={15} // 지도 초기 확대 배율
      >
        <Marker
          key={1}
          position={{ lat: props.lat, lng: props.lng }}
          animation={2}
          onClick={() => {}}
        />
      </NaverMap>
    </RenderAfterNavermapsLoaded>
  );
}

export default CustomMap;
