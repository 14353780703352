import React, { useState } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Auth from "../screens/Auth/Auth";
import Write from "../screens/Write/Write";
import Brown from "../screens/Theme/Brown/Brown";
import BrwonDotSms from "../screens/Theme/Brown/BrwonDotSms";
import BrownKo from "../screens/Theme/Brown/BrownKo";
import BrwonNo from "../screens/Theme/Brown/BrwonNo";
import CallendarErr from "../screens/Theme/Brown/CallendarErr";
import BrownCal from "../screens/Theme/Brown/BrownCal";
import BrownNoCal from "../screens/Theme/Brown/BrownNoCal";
import CustomSewoong from "../screens/Theme/Brown/CustomSewoong";
import CustomJieon from "../screens/Theme/Brown/CustomJieon";
import CustomHejin from "../screens/Theme/Brown/CustomHejin";

import White from "../screens/Theme/White/White";
import WhiteKo from "../screens/Theme/White/WhiteKo";
import WhiteCal from "../screens/Theme/White/WhiteCal";
import WhiteDdo from "../screens/Theme/White/WhiteDdo";
import WhiteNo from "../screens/Theme/White/WhiteNo";
import CustomNihon from "../screens/Theme/White/CustomNihon";
import WhiteCorona from "../screens/Theme/White/WhiteCorona";
import WhiteWord from "../screens/Theme/White/WhiteWord";
import CustomSeockwon from "../screens/Theme/White/CustomSeockwon";
import CustomTai from "../screens/Theme/White/CustomTai";
import CustomChewyul from "../screens/Theme/White/CustomChewyul";
import CustomKyongmin from "../screens/Theme/White/CustomKyongmin";
import CustomDain from "../screens/Theme/White/CustomDain";
import CustomDain2 from "../screens/Theme/White/CustomDain2";

import Max from "../screens/Theme/Max/Max";
import Max21 from "../screens/Theme/Max/Max21";
import MaxClock from "../screens/Theme/Max/MaxClock";
import MaxPopup from "../screens/Theme/Max/MaxPopup";
import MaxParents from "../screens/Theme/Max/MaxParents";
import CustomJaeeun from "../screens/Theme/Max/CustomJaeeun";
import CustomSumi from "../screens/Theme/Max/CustomSumi";

import ReBrown from "../screens/Theme/Brown/ReBrown";
import ReBrown21 from "../screens/Theme/Brown/ReBrwon21";
import ReBrownAccount from "../screens/Theme/Brown/ReBrownAccount";
import ReBrownGalSmall from "../screens/Theme/Brown/ReBrownGalSmall";
import ReBrownTime from "../screens/Theme/Brown/ReBrownTime";
import CustomSeongChe from "../screens/Theme/Brown/CustomSeongChe";
import BrownMessage from "../screens/Theme/Brown/BrownMessage";
import CustomHan from "../screens/Theme/Brown/CustomHan";

import ReWhite from "../screens/Theme/White/ReWhite";
import ReWhite21 from "../screens/Theme/White/ReWhite21";
import CustomHyeonju from "../screens/Theme/White/CustomHyeonju";
import CustomSujin from "../screens/Theme/White/CustomSujin";

import IllMax from "../screens/Theme/IllMax/IllMax";

import ThankMax from "../screens/Theme/Thank/ThankMax/ThankMax";
import CustomDain3 from "../screens/Theme/White/CustomDain3";
import CustomDain4 from "../screens/Theme/White/CustomDain4";
import CustomSolgil from "../screens/Theme/Max/CustomSolgil";
import CustomYoonjae from "../screens/Theme/Brown/CustomYoonjae";
import CustomSohee from "../screens/Theme/Max/CustomSohee";
import CustomHonju from "../screens/Theme/Max/CustomHonju";

const AppRouter = ({ isLoggedIn, props }) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/brown/:id/:path">
          <ReBrown />
        </Route>

        <Route exact path="/brown21/:id/:path">
          <ReBrown21 />
        </Route>

        <Route exact path="/brwacc/:id/:path">
          <ReBrownAccount />
        </Route>

        <Route exact path="/browngal/:id/:path">
          <ReBrownGalSmall />
        </Route>

        <Route exact path="/brownMe/:id/:path">
          <BrownMessage />
        </Route>

        <Route exact path="/brti/:id/:path">
          <ReBrownTime />
        </Route>

        <Route exact path="/brw/:id/:path">
          <Brown />
        </Route>

        <Route exact path="/brwdot/:id/:path">
          <BrwonDotSms />
        </Route>

        <Route exact path="/brwca/:id/:path">
          <BrownCal />
        </Route>

        <Route exact path="/brwnoca/:id/:path">
          <BrownNoCal />
        </Route>

        <Route exact path="/brwko/:id/:path">
          <BrownKo />
        </Route>

        <Route exact path="/brwno/:id/:path">
          <BrwonNo />
        </Route>

        <Route exact path="/brwcal/:id/:path">
          <CallendarErr />
        </Route>

        <Route exact path="/brwcus1/:id/:path">
          <CustomSewoong />
        </Route>
        <Route exact path="/brwcus2/:id/:path">
          <CustomJieon />
        </Route>
        <Route exact path="/brwcus3/:id/:path">
          <CustomHejin />
        </Route>

        <Route exact path="/brwcus4/:id/:path">
          <CustomSeongChe />
        </Route>

        <Route exact path="/brwcus5/:id/:path">
          <CustomYoonjae />
        </Route>

        <Route exact path="/brwcus6/:id/:path">
          <CustomHan />
        </Route>

        <Route exact path="/white/:id/:path">
          <ReWhite />
        </Route>

        <Route exact path="/white21/:id/:path">
          <ReWhite21 />
        </Route>

        <Route exact path="/prm/:id/:path">
          <White />
        </Route>

        <Route exact path="/prmko/:id/:path">
          <WhiteKo />
        </Route>

        <Route exact path="/prmcal/:id/:path">
          <WhiteCal />
        </Route>

        <Route exact path="/prmddo/:id/:path">
          <WhiteDdo />
        </Route>

        <Route exact path="/prmno/:id/:path">
          <WhiteNo />
        </Route>

        <Route exact path="/prmco/:id/:path">
          <WhiteCorona />
        </Route>

        <Route exact path="/prmwo/:id/:path">
          <WhiteWord />
        </Route>

        <Route exact path="/cus1/:id/:path">
          <CustomSeockwon />
        </Route>

        <Route exact path="/cus2/:id/:path">
          <CustomTai />
        </Route>

        <Route exact path="/cus3/:id/:path">
          <CustomNihon />
        </Route>

        <Route exact path="/cus4/:id/:path">
          <CustomChewyul />
        </Route>

        <Route exact path="/cus5/:id/:path">
          <CustomKyongmin />
        </Route>

        <Route exact path="/cus6/:id/:path">
          <CustomDain />
        </Route>

        <Route exact path="/cus7/:id/:path">
          <CustomDain2 />
        </Route>

        <Route exact path="/cus8/:id/:path">
          <CustomHyeonju />
        </Route>

        <Route exact path="/cus9/:id/:path">
          <CustomSujin />
        </Route>

        <Route exact path="/cus10/:id/:path">
          <CustomDain3 />
        </Route>

        <Route exact path="/cus11/:id/:path">
          <CustomDain4 />
        </Route>

        <Route exact path="/max/:id/:path">
          <Max />
        </Route>

        <Route exact path="/max21/:id/:path">
          <Max21 />
        </Route>

        <Route exact path="/maxcl/:id/:path">
          <MaxClock />
        </Route>

        <Route exact path="/maxpop/:id/:path">
          <MaxPopup />
        </Route>

        <Route exact path="/maxpa/:id/:path">
          <MaxParents />
        </Route>

        <Route exact path="/maxcus1/:id/:path">
          <CustomJaeeun />
        </Route>

        <Route exact path="/maxcus2/:id/:path">
          <CustomSolgil />
        </Route>

        <Route exact path="/maxcus3/:id/:path">
          <CustomSumi />
        </Route>

        <Route exact path="/maxcus4/:id/:path">
          <CustomSohee />
        </Route>

        <Route exact path="/maxcus5/:id/:path">
          <CustomHonju />
        </Route>

        <Route exact path="/illmax/:id/:path">
          <IllMax />
        </Route>

        <Route exact path="/tmax/:id/:path">
          <ThankMax />
        </Route>

        {isLoggedIn ? (
          <>
            <Route exact path="/write">
              <Write />
            </Route>
          </>
        ) : (
          <Route exact path="/write">
            <Auth />
          </Route>
        )}
      </Switch>
    </Router>
  );
};

export default AppRouter;
