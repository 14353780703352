import React, { useState } from "react";
import Modal from "react-modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "330px",
    textAlign: "center",
  },
};
export default function ModalAccount(props) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <div>
      <Modal isOpen={isOpenModal} style={customStyles}>
        <div style={{ flex: 1 }}>
          <div style={{}}>
            <p
              style={{
                padding: "0 6px 6px",
                borderBottom: "1px solid #333",
                fontSize: 14,
              }}
            >
              {ReactHtmlParser(props.basic)}
            </p>
          </div>
          <div
            style={{
              fontSize: props.modalFont === "" ? 12 : props.modalFont,
              lineHeight: "14px",
            }}
          >
            <div style={{ marginTop: 14 }}>{ReactHtmlParser(props.bank)}</div>
            <div>{ReactHtmlParser(props.account)}</div>
            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
              예금주 : {ReactHtmlParser(props.name)}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <CopyToClipboard
            text={props.account}
            onCopy={() => {
              alert("복사가 완료되었습니다.");
            }}
          >
            <div
              style={{
                flex: 1,
                marginRight: 10,
                height: 30,
                backgroundColor: "#e9e9e9",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 5,
                cursor: "pointer",
              }}
            >
              <span style={{}}>복사하기</span>
            </div>
          </CopyToClipboard>
          <div
            style={{
              flex: 1,
              height: 30,
              backgroundColor: "#333",
              fontSize: 12,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
              cursor: "pointer",
            }}
            onClick={() => {
              setIsOpenModal(false);
            }}
          >
            <span style={{ color: "#fff" }}>닫기</span>
          </div>
        </div>
      </Modal>
      <button
        style={{
          display: "inline-block",
          width: 60,
          height: "22.66px",
          backgroundColor: "#333",
          borderRadius: 15,
          cursor: "pointer",
          border: "none",
        }}
        onClick={() => {
          setIsOpenModal(true);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <span style={{ color: "#fff", fontSize: 10 }}>계좌번호</span>
        </div>
      </button>
    </div>
  );
}
