import React from "react";
import {
  faPhoneAlt,
  faEnvelope,
  faShareAlt,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAccount from "../../components/ModalAccount.js/ModalAccount";
import kakaoImg from "../../assets/images/kakaopay.png";

export default function ParentCallComponent(props) {
  return (
    <div
      data-aos={props.isSharu ? "fade-up" : ""}
      style={{
        display: "flex",
        padding: "0 20px",
        paddingTop: 30,
      }}
    >
      {props.husbandHostPhone === "" && props.optionHusbandMama === "" ? (
        false
      ) : (
        <div style={{ flex: 1 }}>
          <p style={{ margin: 0 }}>
            <span
              style={{
                borderBottom: "1px solid #333",
                paddingBottom: 10,
              }}
            >
              {props.basic_12 !== "" ? props.basic_12 : "신랑측 혼주"}
            </span>
          </p>
          <div
            style={{
              display:
                props.wifeHostPhone === "" && props.optionWifeMama === ""
                  ? "flex"
                  : "block",
            }}
          >
            {props.husbandHostPhone != "" ? (
              <div style={{ paddingTop: 20, flex: 1, height: 120 }}>
                <p>아버지 {ReactHtmlParser(props.husbandPapa)}</p>
                {props.husbandHostPhone === "0" ? (
                  false
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 18,
                      color: "#333",
                    }}
                  >
                    <a
                      href={`tel:${props.husbandHostPhone}`}
                      style={{
                        padding: "3px 8px",
                        marginRight: 10,
                        color: "#333",
                      }}
                    >
                      <FontAwesomeIcon icon={faPhoneAlt} size="14" />
                    </a>
                    <a
                      href={`sms:${props.husbandHostPhone}`}
                      style={{ padding: "3px 8px", color: "#333" }}
                    >
                      <FontAwesomeIcon icon={faEnvelope} size="14" />
                    </a>
                  </div>
                )}

                <div
                  style={{
                    marginTop: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {props.path === "account" && props.accountVer === 2 ? (
                    props.optionLeftBank === "" ? (
                      false
                    ) : (
                      <ModalAccount
                        modalFont={props.modalFont}
                        basic={props.basic_8}
                        bank={props.optionLeftBank}
                        account={props.optionLeftAccount}
                        name={props.optionLeftName}
                      />
                    )
                  ) : (
                    false
                  )}
                  {props.id === "OHPe4iXrf2K8jAISRvaz" &&
                  props.path === "none" ? (
                    <ModalAccount
                      modalFont={props.modalFont}
                      basic={props.basic_8}
                      bank={props.optionLeftBank}
                      account={props.optionLeftAccount}
                      name={props.optionLeftName}
                    />
                  ) : (
                    false
                  )}
                  {props.husbandPapaKakao != "" ? (
                    <div style={{ display: "inline-block" }}>
                      <a
                        href={`https://qr.kakaopay.com/${props.husbandPapaKakao}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={kakaoImg}
                          style={{
                            width: 50,
                            borderRadius: 50,
                            padding: "0 5px",
                          }}
                        />
                      </a>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            ) : (
              false
            )}

            {props.optionHusbandMama != "" ? (
              <div style={{ paddingTop: 20, flex: 1 }}>
                <p>어머니 {ReactHtmlParser(props.husbandMama)}</p>
                {props.optionHusbandMama === "0" ? (
                  false
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 18,
                      color: "#333",
                    }}
                  >
                    <a
                      href={`tel:${props.optionHusbandMama}`}
                      style={{
                        padding: "3px 8px",
                        marginRight: 10,
                        color: "#333",
                      }}
                    >
                      <FontAwesomeIcon icon={faPhoneAlt} size="14" />
                    </a>
                    <a
                      href={`sms:${props.optionHusbandMama}`}
                      style={{ padding: "3px 8px", color: "#333" }}
                    >
                      <FontAwesomeIcon icon={faEnvelope} size="14" />
                    </a>
                  </div>
                )}

                <div
                  style={{
                    marginTop: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {props.path === "account" && props.accountVer === 2 ? (
                    props.optionBottomLeftBank === "" ? (
                      false
                    ) : (
                      <ModalAccount
                        modalFont={props.modalFont}
                        basic={props.basic_10}
                        bank={props.optionBottomLeftBank}
                        account={props.optionBottomLeftAccount}
                        name={props.optionBottomLeftName}
                      />
                    )
                  ) : (
                    false
                  )}

                  {props.husbandMamaKakao != "" ? (
                    <div style={{ display: "inline-block" }}>
                      <a
                        href={`https://qr.kakaopay.com/${props.husbandMamaKakao}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={kakaoImg}
                          style={{
                            width: 50,
                            borderRadius: 50,
                            padding: "0 5px",
                          }}
                        />
                      </a>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        </div>
      )}
      {props.wifeHostPhone === "" && props.optionWifeMama === "" ? (
        false
      ) : (
        <div style={{ flex: 1 }}>
          <p style={{ margin: 0 }}>
            <span
              style={{
                borderBottom: "1px solid #333",
                paddingBottom: 10,
              }}
            >
              {props.basic_13 !== "" ? props.basic_13 : "신부측 혼주"}
            </span>
          </p>
          <div
            style={{
              display:
                props.husbandHostPhone === "" && props.optionHusbandMama === ""
                  ? "flex"
                  : "block",
            }}
          >
            {props.wifeHostPhone != "" ? (
              <div style={{ paddingTop: 20, flex: 1, height: 120 }}>
                <p>아버지 {ReactHtmlParser(props.wifePapa)}</p>
                {props.wifeHostPhone === "0" ? (
                  false
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 18,
                      color: "#333",
                    }}
                  >
                    <a
                      href={`tel:${props.wifeHostPhone}`}
                      style={{
                        padding: "3px 8px",
                        marginRight: 10,
                        color: "#333",
                      }}
                    >
                      <FontAwesomeIcon icon={faPhoneAlt} size="14" />
                    </a>
                    <a
                      href={`sms:${props.wifeHostPhone}`}
                      style={{ padding: "3px 8px", color: "#333" }}
                    >
                      <FontAwesomeIcon icon={faEnvelope} size="14" />
                    </a>
                  </div>
                )}

                <div
                  style={{
                    marginTop: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {props.path === "account" && props.accountVer === 2 ? (
                    props.optionRightName === "" ? (
                      false
                    ) : (
                      <ModalAccount
                        modalFont={props.modalFont}
                        basic={props.basic_9}
                        bank={props.optionRightBank}
                        account={props.optionRightAccount}
                        name={props.optionRightName}
                      />
                    )
                  ) : (
                    false
                  )}

                  {props.wifePapaKakao != "" ? (
                    <div style={{ display: "inline-block" }}>
                      <a
                        href={`https://qr.kakaopay.com/${props.wifePapaKakao}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={kakaoImg}
                          style={{
                            width: 50,
                            borderRadius: 50,
                            padding: "0 5px",
                          }}
                        />
                      </a>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            ) : (
              false
            )}
            {props.optionWifeMama != "" ? (
              <div style={{ paddingTop: 20, flex: 1 }}>
                <p>어머니 {ReactHtmlParser(props.wifeMama)}</p>
                {props.optionWifeMama === "0" ? (
                  false
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 18,
                      color: "#333",
                    }}
                  >
                    <a
                      href={`tel:${props.optionWifeMama}`}
                      style={{
                        padding: "3px 8px",
                        marginRight: 10,
                        color: "#333",
                      }}
                    >
                      <FontAwesomeIcon icon={faPhoneAlt} size="14" />
                    </a>
                    <a
                      href={`sms:${props.optionWifeMama}`}
                      style={{ padding: "3px 8px", color: "#333" }}
                    >
                      <FontAwesomeIcon icon={faEnvelope} size="14" />
                    </a>
                  </div>
                )}

                <div
                  style={{
                    marginTop: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {props.path === "account" && props.accountVer === 2 ? (
                    props.optionBottomRightBank === "" ? (
                      false
                    ) : (
                      <ModalAccount
                        modalFont={props.modalFont}
                        basic={props.basic_11}
                        bank={props.optionBottomRightBank}
                        account={props.optionBottomRightAccount}
                        name={props.optionBottomRightName}
                      />
                    )
                  ) : (
                    false
                  )}
                  {props.id === "OHPe4iXrf2K8jAISRvaz" &&
                  props.path === "none" ? (
                    <ModalAccount
                      modalFont={props.modalFont}
                      basic={props.basic_11}
                      bank={props.optionBottomRightBank}
                      account={props.optionBottomRightAccount}
                      name={props.optionBottomRightName}
                    />
                  ) : (
                    false
                  )}

                  {props.wifeMamaKakao != "" ? (
                    <div style={{ display: "inline-block" }}>
                      <a
                        href={`https://qr.kakaopay.com/${props.wifeMamaKakao}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={kakaoImg}
                          style={{
                            width: 50,
                            borderRadius: 50,
                            padding: "0 5px",
                          }}
                        />
                      </a>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        </div>
      )}
    </div>
  );
}
