import React, { useState } from "react";
import { authService, dbService, storageService } from "../../fbase";
import { v4 as uuidv4 } from "uuid";
import "./Write.css";
const Write = () => {
  const [applicant, setApplicant] = useState("");
  const [accountVer, setAccountVer] = useState(1);
  const [animeVer, setAnimeVer] = useState(1);
  const [isSharu, setIsSharu] = useState(true);
  const [isAnime, setIsAnime] = useState(true);
  const [isMusic, setIsMusic] = useState(true);
  const [autoPlay, setAutoPlay] = useState(true);

  const [husbandName, setHusbandName] = useState("");
  const [husbandShortName, setHusbandShortName] = useState("");
  const [husbandPhone, setHusbandPhone] = useState("");
  const [husbandKakaoPay, setHusbandKakaoPay] = useState("");
  const [husbandPapa, setHusbandPapa] = useState("");
  const [husbandMama, setHusbandMama] = useState("");
  const [husbandFirstSon, setHusbandFirstSon] = useState("");
  const [husbandAccount, setHusbandAccount] = useState("");
  const [husbandBank, setHusbandBank] = useState("");
  const [husbandAccountName, setHusbandAccountName] = useState("");
  const [husbandHostPhone, setHusbandHostPhone] = useState("");

  const [wifeName, setWifeName] = useState("");
  const [wifeShortName, setWifeShortName] = useState("");
  const [wifePhone, setWifePhone] = useState("");
  const [wifeKakaoPay, setWifeKakaoPay] = useState("");
  const [wifePapa, setWifePapa] = useState("");
  const [wifeMama, setWifeMama] = useState("");
  const [wifeFirstDau, setWifeFirstDau] = useState("");
  const [wifeAccount, setWifeAccount] = useState("");
  const [wifeBank, setWifeBank] = useState("");
  const [wifeAccountName, setWifeAccountName] = useState("");
  const [wifeHostPhone, setWifeHostPhone] = useState("");

  const [weddingDate, setWeddingDate] = useState("");
  const [weddingLocation_1, setWeddingLocation_1] = useState("");
  const [weddingLocation_2, setWeddingLocation_2] = useState("");
  const [weddingNumber, setWeddingNumber] = useState("");
  const [weddingHow, setWeddingHow] = useState("");
  const [weddingAddress, setWeddingAddress] = useState("");
  const [invatationContent, setInvatationContent] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [kakaoTitle, setKakaoTitle] = useState("");

  const [optionLeftName, setOptionLeftName] = useState("");
  const [optionLeftBank, setOptionLeftBank] = useState("");
  const [optionLeftAccount, setOptionLeftAccount] = useState("");

  const [optionRightName, setOptionRightName] = useState("");
  const [optionRightBank, setOptionRightBank] = useState("");
  const [optionRightAccount, setOptionRightAccount] = useState("");

  const [optionBottomLeftName, setOptionBottomLeftName] = useState("");
  const [optionBottomLeftBank, setOptionBottomLeftBank] = useState("");
  const [optionBottomLeftAccount, setOptionBottomLeftAccount] = useState("");

  const [optionBottomRightName, setOptionBottomRightName] = useState("");
  const [optionBottomRightBank, setOptionBottomRightBank] = useState("");
  const [optionBottomRightAccount, setOptionBottomRightAccount] = useState("");

  const [optionHusbandMama, setOptionHusbandMama] = useState("");
  const [optionWifeMama, setOptionWifeMama] = useState("");

  const [basic_1, setBasic_1] = useState(
    "참석이 어려우신 분들은 <br/> 축하의 마음을 전해주세요"
  );
  const [basic_2, setBasic_2] = useState("신랑에게 연락하기");
  const [basic_3, setBasic_3] = useState("신부에게 연락하기");
  const [basic_4, setBasic_4] = useState("신랑 혼주에게 연락하기");
  const [basic_5, setBasic_5] = useState("신부 혼주에게 연락하기");
  const [basic_6, setBasic_6] = useState("신랑측");
  const [basic_7, setBasic_7] = useState("신부측");
  const [basic_8, setBasic_8] = useState("신랑측 혼주");
  const [basic_9, setBasic_9] = useState("신부측 혼주");
  const [basic_10, setBasic_10] = useState("신랑측 어머니");
  const [basic_11, setBasic_11] = useState("신부측 어머니");

  const [mainImg, setMainImg] = useState();
  const [galleryImg_1, setGalleryImg_1] = useState();
  const [galleryImg_2, setGalleryImg_2] = useState();
  const [galleryImg_3, setGalleryImg_3] = useState();
  const [galleryImg_4, setGalleryImg_4] = useState();
  const [galleryImg_5, setGalleryImg_5] = useState();
  const [galleryImg_6, setGalleryImg_6] = useState();
  const [galleryImg_7, setGalleryImg_7] = useState();
  const [galleryImg_8, setGalleryImg_8] = useState();
  const [galleryImg_9, setGalleryImg_9] = useState();
  const [galleryImg_10, setGalleryImg_10] = useState();

  const onLogOutClick = () => authService.signOut();

  const onSubmit = async (event) => {
    event.preventDefault();
    alert("눌렀다 한 번더 누르면 죽인다");

    let galleryUrl_1 = "";
    let galleryUrl_2 = "";
    let galleryUrl_3 = "";
    let galleryUrl_4 = "";
    let galleryUrl_5 = "";
    let galleryUrl_6 = "";
    let galleryUrl_7 = "";
    let galleryUrl_8 = "";
    let galleryUrl_9 = "";
    let galleryUrl_10 = "";

    const fileRef = storageService.ref().child(`img/${applicant}/${uuidv4()}`);
    const response = await fileRef.putString(mainImg, "data_url");
    const downloadUrlMain = await response.ref.getDownloadURL();

    if (galleryImg_1 !== undefined) {
      const gallery_1 = storageService
        .ref()
        .child(`img/${applicant}/${uuidv4()}`);
      const galleryResponse_1 = await gallery_1.putString(
        galleryImg_1,
        "data_url"
      );
      galleryUrl_1 = await galleryResponse_1.ref.getDownloadURL();
    }

    if (galleryImg_2 !== undefined) {
      const gallery_2 = storageService
        .ref()
        .child(`img/${applicant}/${uuidv4()}`);
      const galleryResponse_2 = await gallery_2.putString(
        galleryImg_2,
        "data_url"
      );
      galleryUrl_2 = await galleryResponse_2.ref.getDownloadURL();
    }

    if (galleryImg_2 !== undefined) {
      const gallery_2 = storageService
        .ref()
        .child(`img/${applicant}/${uuidv4()}`);
      const galleryResponse_2 = await gallery_2.putString(
        galleryImg_2,
        "data_url"
      );
      galleryUrl_2 = await galleryResponse_2.ref.getDownloadURL();
    }

    if (galleryImg_3 !== undefined) {
      const gallery_3 = storageService
        .ref()
        .child(`img/${applicant}/${uuidv4()}`);
      const galleryResponse_3 = await gallery_3.putString(
        galleryImg_3,
        "data_url"
      );
      galleryUrl_3 = await galleryResponse_3.ref.getDownloadURL();
    }

    if (galleryImg_4 !== undefined) {
      const gallery_4 = storageService
        .ref()
        .child(`img/${applicant}/${uuidv4()}`);
      const galleryResponse_4 = await gallery_4.putString(
        galleryImg_4,
        "data_url"
      );
      galleryUrl_4 = await galleryResponse_4.ref.getDownloadURL();
    }

    if (galleryImg_5 !== undefined) {
      const gallery_5 = storageService
        .ref()
        .child(`img/${applicant}/${uuidv4()}`);
      const galleryResponse_5 = await gallery_5.putString(
        galleryImg_5,
        "data_url"
      );
      galleryUrl_5 = await galleryResponse_5.ref.getDownloadURL();
    }

    if (galleryImg_6 !== undefined) {
      const gallery_6 = storageService
        .ref()
        .child(`img/${applicant}/${uuidv4()}`);
      const galleryResponse_6 = await gallery_6.putString(
        galleryImg_6,
        "data_url"
      );
      galleryUrl_6 = await galleryResponse_6.ref.getDownloadURL();
    }

    if (galleryImg_7 !== undefined) {
      const gallery_7 = storageService
        .ref()
        .child(`img/${applicant}/${uuidv4()}`);
      const galleryResponse_7 = await gallery_7.putString(
        galleryImg_7,
        "data_url"
      );
      galleryUrl_7 = await galleryResponse_7.ref.getDownloadURL();
    }

    if (galleryImg_8 !== undefined) {
      const gallery_8 = storageService
        .ref()
        .child(`img/${applicant}/${uuidv4()}`);
      const galleryResponse_8 = await gallery_8.putString(
        galleryImg_8,
        "data_url"
      );
      galleryUrl_8 = await galleryResponse_8.ref.getDownloadURL();
    }

    if (galleryImg_9 !== undefined) {
      const gallery_9 = storageService
        .ref()
        .child(`img/${applicant}/${uuidv4()}`);
      const galleryResponse_9 = await gallery_9.putString(
        galleryImg_9,
        "data_url"
      );
      galleryUrl_9 = await galleryResponse_9.ref.getDownloadURL();
    }

    if (galleryImg_10 !== undefined) {
      const gallery_10 = storageService
        .ref()
        .child(`img/${applicant}/${uuidv4()}`);
      const galleryResponse_10 = await gallery_10.putString(
        galleryImg_10,
        "data_url"
      );
      galleryUrl_10 = await galleryResponse_10.ref.getDownloadURL();
    }

    const content = {
      createdAt: Date.now(),
      accountVer,
      animeVer,
      isSharu,
      isAnime,
      isMusic,
      autoPlay,
      applicant,
      husbandName,
      husbandShortName,
      husbandPhone,
      husbandKakaoPay,
      husbandPapa,
      husbandMama,
      husbandFirstSon,
      husbandAccount,
      husbandBank,
      husbandAccountName,
      husbandHostPhone,

      wifeName,
      wifeShortName,
      wifePhone,
      wifeKakaoPay,
      wifePapa,
      wifeMama,
      wifeFirstDau,
      wifeAccount,
      wifeBank,
      wifeAccountName,
      wifeHostPhone,

      weddingDate,
      weddingLocation_1,
      weddingLocation_2,
      weddingNumber,
      weddingAddress,
      latitude,
      longitude,
      kakaoTitle,
      weddingHow,
      invatationContent,

      basic_1,
      basic_2,
      basic_3,
      basic_4,
      basic_5,
      basic_6,
      basic_7,
      basic_8,
      basic_9,
      basic_10,
      basic_11,

      optionLeftName,
      optionLeftBank,
      optionLeftAccount,
      optionRightName,
      optionRightBank,
      optionRightAccount,

      optionBottomLeftAccount,
      optionBottomLeftBank,
      optionBottomLeftName,
      optionBottomRightAccount,
      optionBottomRightBank,
      optionBottomRightName,

      optionHusbandMama,
      optionWifeMama,

      downloadUrlMain,
      galleryUrl_1,
      galleryUrl_2,
      galleryUrl_3,
      galleryUrl_4,
      galleryUrl_5,
      galleryUrl_6,
      galleryUrl_7,
      galleryUrl_8,
      galleryUrl_9,
      galleryUrl_10,
    };
    dbService.collection("users").add(content);
  };

  const onChange = (event) => {
    // console.log(event.target.name);
    const {
      target: { name, value },
    } = event;

    if (name === "husbandName") {
      setHusbandName(value);
      // console.log(value);
    } else if (name === "applicant") {
      setApplicant(value);
    } else if (name === "husbandShortName") {
      setHusbandShortName(value);
    } else if (name === "husbandPhone") {
      setHusbandPhone(value);
    } else if (name === "husbandKakaoPay") {
      setHusbandKakaoPay(value);
    } else if (name === "husbandPapa") {
      setHusbandPapa(value);
    } else if (name === "husbandMama") {
      setHusbandMama(value);
    } else if (name === "husbandFirstSon") {
      setHusbandFirstSon(value);
    } else if (name === "husbandAccount") {
      setHusbandAccount(value);
    } else if (name === "husbandBank") {
      setHusbandBank(value);
    } else if (name === "husbandAccountName") {
      setHusbandAccountName(value);
    } else if (name === "husbandHostPhone") {
      setHusbandHostPhone(value);
    } else if (name === "wifeName") {
      setWifeName(value);
    } else if (name === "wifeShortName") {
      setWifeShortName(value);
    } else if (name === "wifePhone") {
      setWifePhone(value);
    } else if (name === "wifeKakaoPay") {
      setWifeKakaoPay(value);
    } else if (name === "wifePapa") {
      setWifePapa(value);
    } else if (name === "wifeMama") {
      setWifeMama(value);
    } else if (name === "wifeFirstDau") {
      setWifeFirstDau(value);
    } else if (name === "wifeAccount") {
      setWifeAccount(value);
    } else if (name === "wifeBank") {
      setWifeBank(value);
    } else if (name === "wifeAccountName") {
      setWifeAccountName(value);
    } else if (name === "wifeHostPhone") {
      setWifeHostPhone(value);
    } else if (name === "weddingDate") {
      setWeddingDate(value);
    } else if (name === "weddingLocation_1") {
      setWeddingLocation_1(value);
    } else if (name === "weddingLocation_2") {
      setWeddingLocation_2(value);
    } else if (name === "weddingNumber") {
      setWeddingNumber(value);
    } else if (name === "weddingAddress") {
      setWeddingAddress(value);
    } else if (name === "latitude") {
      setLatitude(value);
    } else if (name === "longitude") {
      setLongitude(value);
    } else if (name === "kakaoTitle") {
      setKakaoTitle(value);
    } else if (name === "weddingHow") {
      setWeddingHow(value);
    } else if (name === "invatationContent") {
      setInvatationContent(value);
    } else if (name === "basic_1") {
      setBasic_1(value);
    } else if (name === "basic_2") {
      setBasic_2(value);
    } else if (name === "basic_3") {
      setBasic_3(value);
    } else if (name === "basic_4") {
      setBasic_4(value);
    } else if (name === "basic_5") {
      setBasic_5(value);
    } else if (name === "basic_6") {
      setBasic_6(value);
    } else if (name === "basic_7") {
      setBasic_7(value);
    } else if (name === "basic_8") {
      setBasic_8(value);
    } else if (name === "basic_9") {
      setBasic_9(value);
    } else if (name === "basic_10") {
      setBasic_10(value);
    } else if (name === "basic_11") {
      setBasic_11(value);
    } else if (name === "accountVer") {
      setAccountVer(parseInt(value));
    } else if (name === "animeVer") {
      setAnimeVer(parseInt(value));
    } else if (name === "isSharu") {
      setIsSharu(value === "true");
    } else if (name === "isAnime") {
      setIsAnime(value === "true");
      console.log(value === "true");
    } else if (name === "isMusic") {
      setIsMusic(value === "true");
      console.log(value === "true");
    } else if (name === "autoPlay") {
      setAutoPlay(value === "true");
      console.log(value === "true");
    } else if (name === "optionLeftName") {
      setOptionLeftName(value);
    } else if (name === "optionLeftBank") {
      setOptionLeftBank(value);
    } else if (name === "optionLeftAccount") {
      setOptionLeftAccount(value);
    } else if (name === "optionRightName") {
      setOptionRightName(value);
    } else if (name === "optionRightBank") {
      setOptionRightBank(value);
    } else if (name === "optionRightAccount") {
      setOptionRightAccount(value);
    } else if (name === "optionHusbandMama") {
      setOptionHusbandMama(value);
    } else if (name === "optionWifeMama") {
      setOptionWifeMama(value);
    } else if (name === "optionBottomLeftAccount") {
      setOptionBottomLeftAccount(value);
    } else if (name === "optionBottomLeftBank") {
      setOptionBottomLeftBank(value);
    } else if (name === "optionBottomLeftName") {
      setOptionBottomLeftName(value);
    } else if (name === "optionBottomRightAccount") {
      setOptionBottomRightAccount(value);
    } else if (name === "optionBottomRightBank") {
      setOptionBottomRightBank(value);
    } else if (name === "optionBottomRightName") {
      setOptionBottomRightName(value);
    }
  };

  const onFileChange = (event) => {
    const {
      target: { files, name },
    } = event;

    const theFile = files[0];
    const reader = new FileReader();

    reader.onloadend = (finishedEvent) => {
      // console.log(finishedEvent.target.result);
      const {
        currentTarget: { result },
      } = finishedEvent;

      if (name === "mainImg") {
        setMainImg(result);
      } else if (name === "galleryImg_1") {
        setGalleryImg_1(result);
      } else if (name === "galleryImg_2") {
        setGalleryImg_2(result);
      } else if (name === "galleryImg_3") {
        setGalleryImg_3(result);
      } else if (name === "galleryImg_4") {
        setGalleryImg_4(result);
      } else if (name === "galleryImg_5") {
        setGalleryImg_5(result);
      } else if (name === "galleryImg_6") {
        setGalleryImg_6(result);
      } else if (name === "galleryImg_7") {
        setGalleryImg_7(result);
      } else if (name === "galleryImg_8") {
        setGalleryImg_8(result);
      } else if (name === "galleryImg_9") {
        setGalleryImg_9(result);
      } else if (name === "galleryImg_10") {
        setGalleryImg_10(result);
      }
    };
    reader.readAsDataURL(theFile);
  };

  const htmlTag = (str) => {
    if (str !== "") {
      return str
        .replaceAll("<", "%26lt;")
        .replaceAll(">", "%26gt;")
        .replaceAll("/", "%26%2347;");
    }
  };

  return (
    <>
      <div style={{ textAlign: "right" }}>
        <button onClick={onLogOutClick}>Log out</button>
      </div>
      <form onSubmit={onSubmit}>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            paddingTop: "100px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
              width: "80%",
            }}
          >
            <div className="custom-input">
              <label>신청인</label>
              <input
                name="applicant"
                value={applicant}
                onChange={onChange}
                type="text"
              />
            </div>
            <div className="custom-input">
              <label>계좌 버전</label>
              <input
                name="accountVer"
                value={1}
                onChange={onChange}
                type="radio"
                id="accountVer_1"
                style={{ width: "30px" }}
              />
              <label for="accountVer_1">노출 형식</label>
              <input
                name="accountVer"
                value={2}
                onChange={onChange}
                type="radio"
                id="accountVer_2"
                style={{ width: "30px" }}
              />
              <label for="accountVer_2">팝업 형식</label>
            </div>

            <div className="custom-input">
              <label>샤르르 효과</label>
              <input
                name="isSharu"
                value={true}
                onChange={onChange}
                type="radio"
                id="sharuYes"
                style={{ width: "30px" }}
              />
              <label for="sharuYes">Yes</label>
              <input
                name="isSharu"
                value={false}
                onChange={onChange}
                type="radio"
                id="sharuNo"
                style={{ width: "30px" }}
              />
              <label for="sharuNo">No</label>
            </div>

            <div className="custom-input">
              <label>배경 효과</label>
              <input
                name="isAnime"
                value={true}
                onChange={onChange}
                type="radio"
                id="animeYes"
                style={{ width: "30px" }}
              />
              <label for="animeYes">Yes</label>
              <input
                name="isAnime"
                value={false}
                onChange={onChange}
                type="radio"
                id="animeNo"
                style={{ width: "30px" }}
              />
              <label for="animeNo">No</label>
            </div>

            <div className="custom-input">
              <label>배경 효과 선택</label>
              <input
                name="animeVer"
                value={1}
                onChange={onChange}
                type="radio"
                id="animeVer_1"
                style={{ width: "30px" }}
              />
              <label for="animeVer_1">눈내리는 화면</label>
              <input
                name="animeVer"
                value={2}
                onChange={onChange}
                type="radio"
                id="animeVer_2"
                style={{ width: "30px" }}
              />
              <label for="animeVer_2">벚꽃 휘날리는 화면</label>
            </div>

            <div className="custom-input">
              <label>배경음악</label>
              <input
                name="isMusic"
                value={true}
                onChange={onChange}
                type="radio"
                id="musicYes"
                style={{ width: "30px" }}
              />
              <label for="musicYes">Yes</label>
              <input
                name="isMusic"
                value={false}
                onChange={onChange}
                type="radio"
                id="musicNo"
                style={{ width: "30px" }}
              />
              <label for="musicNo">No</label>
            </div>

            <div className="custom-input">
              <label>자동재생</label>
              <input
                name="autoPlay"
                value={true}
                onChange={onChange}
                type="radio"
                id="autoPlayYes"
                style={{ width: "30px" }}
              />
              <label for="autoPlayYes">Yes</label>
              <input
                name="autoPlay"
                value={false}
                onChange={onChange}
                type="radio"
                id="autoPlayNo"
                style={{ width: "30px" }}
              />
              <label for="autoPlayNo">No</label>
            </div>

            <div className="custom-input">
              <label>🤵🏻 신랑 성함</label>
              <input
                name="husbandName"
                value={husbandName}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🤵🏻 신랑 이름 (성빼고)</label>
              <input
                name="husbandShortName"
                value={husbandShortName}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🤵🏻 장남? 차남? 아들?</label>
              <input
                name="husbandFirstSon"
                value={husbandFirstSon}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🤵🏻 신랑 전화번호 (-넣어서)</label>
              <input
                name="husbandPhone"
                value={husbandPhone}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🤵🏻 신랑 카카오페이</label>
              <input
                name="husbandKakaoPay"
                value={husbandKakaoPay}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🎅 신랑 아버지 성함 故</label>
              <input
                name="husbandPapa"
                value={husbandPapa}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🎅 신랑 어머니 성함 故</label>
              <input
                name="husbandMama"
                value={husbandMama}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🎅 신랑 아빠 연락처</label>
              <input
                name="husbandHostPhone"
                value={husbandHostPhone}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🎅 신랑 엄마 연락처</label>
              <input
                name="optionHusbandMama"
                value={optionHusbandMama}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🤵🏻 신랑 은행</label>
              <input
                name="husbandBank"
                value={husbandBank}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🤵🏻 신랑 예금주</label>
              <input
                name="husbandAccountName"
                value={husbandAccountName}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🤵🏻 신랑 계좌번호</label>
              <input
                name="husbandAccount"
                value={husbandAccount}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>👰🏾 신부 성함</label>
              <input
                name="wifeName"
                value={wifeName}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>👰🏾 신부 이름 (성빼고)</label>
              <input
                name="wifeShortName"
                value={wifeShortName}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>👰🏾 장녀? 차녀? 딸?</label>
              <input
                name="wifeFirstDau"
                value={wifeFirstDau}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>👰🏾 신부 전화번호 (-넣어서)</label>
              <input
                name="wifePhone"
                value={wifePhone}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>👰🏾 신부 카카오페이</label>
              <input
                name="wifeKakaoPay"
                value={wifeKakaoPay}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🤶🏻 신부 아버지 성함 故</label>
              <input
                name="wifePapa"
                value={wifePapa}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🤶🏻 신부 어머니 성함 故</label>
              <input
                name="wifeMama"
                value={wifeMama}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🤶🏻 신부 아빠 연락처</label>
              <input
                name="wifeHostPhone"
                value={wifeHostPhone}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>🤶🏻 신부 엄마 연락처</label>
              <input
                name="optionWifeMama"
                value={optionWifeMama}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>👰🏾 신부 은행</label>
              <input
                name="wifeBank"
                value={wifeBank}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>👰🏾 신부 예금주</label>
              <input
                name="wifeAccountName"
                value={wifeAccountName}
                onChange={onChange}
                type="text"
              />
            </div>
            <div className="custom-input">
              <label>👰🏾 신부 계좌번호</label>
              <input
                name="wifeAccount"
                value={wifeAccount}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>결혼식 날짜 (2020-03-10 20:00:00)</label>
              <input
                name="weddingDate"
                value={weddingDate}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>결혼식장 장소1</label>
              <input
                name="weddingLocation_1"
                value={weddingLocation_1}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>결혼식장 장소2</label>
              <input
                name="weddingLocation_2"
                value={weddingLocation_2}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>결혼식장 주소</label>
              <input
                name="weddingAddress"
                value={weddingAddress}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>결혼식장 번호(-넣어서)</label>
              <input
                name="weddingNumber"
                value={weddingNumber}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>위도 (ex: 37.565181)</label>
              <input
                name="latitude"
                value={latitude}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>경도 (ex: 126.995728)</label>
              <input
                name="longitude"
                value={longitude}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>카카오톡 타이틀</label>
              <input
                name="kakaoTitle"
                value={kakaoTitle}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>결혼식장 가는 방법</label>
              <textarea
                name="weddingHow"
                value={weddingHow}
                onChange={onChange}
              ></textarea>
            </div>

            <div className="custom-input">
              <label>청첩장 내용</label>
              <textarea
                name="invatationContent"
                value={invatationContent}
                onChange={onChange}
              ></textarea>
            </div>

            <div className="custom-input">
              <label>메인사진</label>
              <input
                name="mainImg"
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
              {mainImg ? <img src={mainImg} height="100px" /> : false}
            </div>

            <div className="custom-input">
              <label>갤러리 사진 1</label>
              <input
                name="galleryImg_1"
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
              {galleryImg_1 ? <img src={galleryImg_1} height="100px" /> : false}
            </div>

            <div className="custom-input">
              <label>갤러리 사진 2</label>
              <input
                name="galleryImg_2"
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
              {galleryImg_2 ? <img src={galleryImg_2} height="100px" /> : false}
            </div>

            <div className="custom-input">
              <label>갤러리 사진 3</label>
              <input
                name="galleryImg_3"
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
              {galleryImg_3 ? <img src={galleryImg_3} height="100px" /> : false}
            </div>

            <div className="custom-input">
              <label>갤러리 사진 4</label>
              <input
                name="galleryImg_4"
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
              {galleryImg_4 ? <img src={galleryImg_4} height="100px" /> : false}
            </div>

            <div className="custom-input">
              <label>갤러리 사진 5</label>
              <input
                name="galleryImg_5"
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
              {galleryImg_5 ? <img src={galleryImg_5} height="100px" /> : false}
            </div>

            <div className="custom-input">
              <label>갤러리 사진 6</label>
              <input
                name="galleryImg_6"
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
              {galleryImg_6 ? <img src={galleryImg_6} height="100px" /> : false}
            </div>

            <div className="custom-input">
              <label>갤러리 사진 7</label>
              <input
                name="galleryImg_7"
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
              {galleryImg_7 ? <img src={galleryImg_7} height="100px" /> : false}
            </div>

            <div className="custom-input">
              <label>갤러리 사진 8</label>
              <input
                name="galleryImg_8"
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
              {galleryImg_8 ? <img src={galleryImg_8} height="100px" /> : false}
            </div>

            <div className="custom-input">
              <label>갤러리 사진 9</label>
              <input
                name="galleryImg_9"
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
              {galleryImg_9 ? <img src={galleryImg_9} height="100px" /> : false}
            </div>

            <div className="custom-input">
              <label>갤러리 사진 10</label>
              <input
                name="galleryImg_10"
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
              {galleryImg_10 ? (
                <img src={galleryImg_10} height="100px" />
              ) : (
                false
              )}
            </div>

            <div className="custom-input">
              <label>참석이 어려우신 분들은 축하의 마음을 전해 주세요</label>
              <input
                name="basic_1"
                value={basic_1}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>신랑에게 연락하기</label>
              <input
                name="basic_2"
                value={basic_2}
                onChange={onChange}
                type="text"
              />
            </div>
            <div className="custom-input">
              <label>신부에게 연락하기</label>
              <input
                name="basic_3"
                value={basic_3}
                onChange={onChange}
                type="text"
              />
            </div>
            <div className="custom-input">
              <label>신랑 혼주에게 연락하기</label>
              <input
                name="basic_4"
                value={basic_4}
                onChange={onChange}
                type="text"
              />
            </div>
            <div className="custom-input">
              <label>신부 혼주에게 연락하기</label>
              <input
                name="basic_5"
                value={basic_5}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>신랑측 혼주</label>
              <input
                name="basic_6"
                value={basic_6}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>신부측 혼주</label>
              <input
                name="basic_7"
                value={basic_7}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>신랑측 혼주</label>
              <input
                name="basic_8"
                value={basic_8}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>신부측 혼주</label>
              <input
                name="basic_9"
                value={basic_9}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>신랑측 어머니</label>
              <input
                name="basic_10"
                value={basic_10}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>신부측 어머니</label>
              <input
                name="basic_11"
                value={basic_11}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label style={{ fontWeight: "bold" }}>신랑 아버지 은행</label>
              <input
                name="optionLeftBank"
                value={optionLeftBank}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label style={{ fontWeight: "bold" }}>신랑 아버지 이름</label>
              <input
                name="optionLeftName"
                value={optionLeftName}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label style={{ fontWeight: "bold" }}>신랑 아버지 계좌번호</label>
              <input
                name="optionLeftAccount"
                value={optionLeftAccount}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>신랑 어머니 은행</label>
              <input
                name="optionBottomLeftBank"
                value={optionBottomLeftBank}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>신랑 어머니 이름</label>
              <input
                name="optionBottomLeftName"
                value={optionBottomLeftName}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>신랑 어머니 계좌번호</label>
              <input
                name="optionBottomLeftAccount"
                value={optionBottomLeftAccount}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label style={{ fontWeight: "bold" }}>신부 아버지 은행</label>
              <input
                name="optionRightBank"
                value={optionRightBank}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label style={{ fontWeight: "bold" }}>신부 아버지 이름</label>
              <input
                name="optionRightName"
                value={optionRightName}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label style={{ fontWeight: "bold" }}>신부 아버지 계좌번호</label>
              <input
                name="optionRightAccount"
                value={optionRightAccount}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>신부 어머니 은행</label>
              <input
                name="optionBottomRightBank"
                value={optionBottomRightBank}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>신부 어머니 이름</label>
              <input
                name="optionBottomRightName"
                value={optionBottomRightName}
                onChange={onChange}
                type="text"
              />
            </div>

            <div className="custom-input">
              <label>신부 어머니 계좌번호</label>
              <input
                name="optionBottomRightAccount"
                value={optionBottomRightAccount}
                onChange={onChange}
                type="text"
              />
            </div>

            <input
              type="submit"
              value={"submit"}
              style={{ height: "60px", margin: "100px 0" }}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default Write;
