import React from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
export default function ParentAccountComponent(props) {
  return (
    <div>
      {(props.optionLeftBank == "" && props.optionRightBank == "") ||
      props.path !== "account" ||
      props.accountVer !== 1 ? (
        false
      ) : (
        <div>
          <div
            data-aos={props.isSharu ? "fade-up" : ""}
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              margin: "0 20px",
              marginTop: 30,
              padding: "13px 0 13px",
              fontWeight: 500,
              fontSize: 14,
              border: "1px solid #333",
              borderRadius: 5,
              borderBottomLeftRadius:
                props.optionBottomLeftAccount || props.optionBottomRightAccount
                  ? 0
                  : 5,
              borderBottomRightRadius:
                props.optionBottomLeftAccount || props.optionBottomRightAccount
                  ? 0
                  : 5,
            }}
          >
            {props.optionLeftBank !== "" ? (
              <div
                style={{
                  flex: 1,
                  borderRight:
                    props.optionRightBank === "" ? "none" : "1px solid #333",
                }}
              >
                <div style={{}}>
                  <span
                    style={{
                      padding: "0 6px 6px",
                      borderBottom: "1px solid #333",
                      fontSize: 14,
                    }}
                  >
                    {ReactHtmlParser(props.basic_8)}
                  </span>
                </div>
                <div style={{ fontSize: 12, lineHeight: "14px" }}>
                  <div style={{ marginTop: 14 }}>
                    {ReactHtmlParser(props.optionLeftBank)}
                  </div>
                  <div>{ReactHtmlParser(props.optionLeftAccount)}</div>
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    예금주 : {ReactHtmlParser(props.optionLeftName)}
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
            {props.optionRightBank !== "" ? (
              <div style={{ flex: 1 }}>
                <div style={{}}>
                  <span
                    style={{
                      padding: "0 6px 6px",
                      borderBottom: "1px solid #333",
                      fontSize: 14,
                    }}
                  >
                    {ReactHtmlParser(props.basic_9)}
                  </span>
                </div>
                <div style={{ fontSize: 12, lineHeight: "14px" }}>
                  <div style={{ marginTop: 14 }}>
                    {ReactHtmlParser(props.optionRightBank)}
                  </div>
                  <div>{ReactHtmlParser(props.optionRightAccount)}</div>
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    예금주 : {ReactHtmlParser(props.optionRightName)}
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
          </div>
          {props.optionBottomLeftAccount == "" &&
          props.optionBottomRightAccount == "" ? (
            false
          ) : (
            <div
              data-aos={props.isSharu ? "fade-up" : ""}
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                margin: "0 20px",
                padding: "13px 0 13px",
                fontWeight: 500,
                fontSize: 14,
                border: "1px solid #333",
                borderRadius: 5,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTop: "none",
              }}
            >
              {props.optionBottomLeftBank !== "" ? (
                <div
                  style={{
                    flex: 1,
                    borderRight:
                      props.optionBottomRightBank === ""
                        ? "none"
                        : "1px solid #333",
                  }}
                >
                  <div style={{}}>
                    <span
                      style={{
                        padding: "0 6px 6px",
                        borderBottom: "1px solid #333",
                        fontSize: 14,
                      }}
                    >
                      {ReactHtmlParser(props.basic_10)}
                    </span>
                  </div>
                  <div style={{ fontSize: 12, lineHeight: "14px" }}>
                    <div style={{ marginTop: 14 }}>
                      {ReactHtmlParser(props.optionBottomLeftBank)}
                    </div>
                    <div>{ReactHtmlParser(props.optionBottomLeftAccount)}</div>
                    <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                      예금주 : {ReactHtmlParser(props.optionBottomLeftName)}
                    </div>
                  </div>
                </div>
              ) : (
                false
              )}
              {props.optionBottomRightBank !== "" ? (
                <div style={{ flex: 1 }}>
                  <div style={{}}>
                    <span
                      style={{
                        padding: "0 6px 6px",
                        borderBottom: "1px solid #333",
                        fontSize: 14,
                      }}
                    >
                      {ReactHtmlParser(props.basic_11)}
                    </span>
                  </div>
                  <div style={{ fontSize: 12, lineHeight: "14px" }}>
                    <div style={{ marginTop: 14 }}>
                      {ReactHtmlParser(props.optionBottomRightBank)}
                    </div>
                    <div>{ReactHtmlParser(props.optionBottomRightAccount)}</div>
                    <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                      예금주 : {ReactHtmlParser(props.optionBottomRightName)}
                    </div>
                  </div>
                </div>
              ) : (
                false
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
